import React from "react";

import { IconError500 } from "../utilities/SvgIcon";

const Error = ({ code }) => {
  if (code === 404) {
    return (
      <div className="error">
        <img className="icon" src="/images/Error404.png" alt="404 error" />
        <div className="title">Page not found</div>
        <div className="message">This page does not exist or was removed</div>
      </div>
    );
  }

  return (
    <div className="error">
      <IconError500 />
      <div className="title">500 - Internal server error</div>
      <div className="message">
        Something went wrong! Please try again later
      </div>
    </div>
  );
};

export default Error;

import React, { useState, useEffect, useContext } from "react";
import { Context } from "../stores/store";
import { useNavigate, useParams } from "react-router-dom";
import Config from "../stores/Config";
import Util from "../utilities/Util";

import { IconSpinner } from "../utilities/SvgIcon";

import DashboardInboxList from "./DashboardInboxList";
import DashboardHeader from "./DashboardHeader";

const DashboardInbox = ({ search, onFilterChanged }) => {
  const { folder } = useParams();

  const [ContextState, ContextDispatch] = useContext(Context);

  const navigate = useNavigate();

  const [apps, setApps] = useState([]);
  const [fetchAppBusy, setFetchAppBusy] = useState(true);

  const [itemIdBusy, setItemIdBusy] = useState([]);

  // filter the apps
  let filtered = apps;
  const cleanSearch = search.trim().toUpperCase();
  if (cleanSearch) {
    filtered = apps.filter((item) => {
      return item.name.toUpperCase().indexOf(cleanSearch) !== -1;
    });
  }

  const convertItemApiResultToArray = (item) => {
    // return a clean and simplified version of the dashboard items for the UI
    return {
      name: item.name,
      id: item.id,
      thumbnail: Util.fixImageUrl(item.thumbnail),
      changed: item.updatedAt,
      folderId: item.folderId,
    };
  };

  useEffect(() => {
    // fetch data from the singular backend
    if (ContextState.authenticationStatus !== "ok") {
      return;
    }

    // remove the old result
    setFetchAppBusy(true);
    setApps([]);

    // construct the URL for the fetch
    let url =
      Config.singularUrl +
      "/apiv2/dashboard/folder/inbox/items?type=controlapp";

    // add the sort query
    switch (ContextState.dashboardSort) {
      case "alphaZA":
        url += "&sortby=name&sortdirection=desc";
        break;
      case "changed19":
        url += "&sortby=updatedAt&sortdirection=asc";
        break;
      case "changed91":
        url += "&sortby=updatedAt&sortdirection=desc";
        break;
      case "alphaAZ":
      default:
        url += "&sortby=name&sortdirection=asc";
        break;
    }

    // fetch the items
    const controller = new AbortController();
    fetch(url, {
      signal: controller.signal,
      method: "GET",
      headers: Util.getFetchHeaders(ContextState),
    })
      .then((res) => res.json())
      .then((result) => {
        Util.handleFetchError(result, ContextDispatch, navigate);

        // get all the app instances and cleanup the data
        let data = result.data.map((item) => {
          return convertItemApiResultToArray(item);
        });

        // set the data
        setApps(data);
        setFetchAppBusy(false);
      })
      .catch((error) => {
        if (!controller.signal.aborted) {
          setFetchAppBusy(false);
        }
      });
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    search,
    folder,
    ContextState.dashboardSort,
    ContextState.authenticationStatus,
    ContextState.dashboardInboxForceReload,
  ]);

  // when we rename an item we do not want to fetch all the data again.
  // we can simple replace the item we have in the app array
  const onUpdateItem = (item) => {
    let clonedApps = apps.map((a) => {
      if (a.id === item.id) {
        return convertItemApiResultToArray(item);
      } else {
        return a;
      }
    });
    setApps(clonedApps);
  };

  const onSetItemIdBusy = (id, busy) => {
    // we work with arrays here
    if (!Array.isArray(id)) {
      id = [id];
    }

    // add them to the list
    if (busy) {
      // make sure we don't add the same id twice
      const filtered = id.filter((item) => !itemIdBusy.includes(item));

      // add the filtered array to the existing array
      if (filtered.length > 0) {
        setItemIdBusy((itemIdBusy) => [...itemIdBusy, ...filtered]);
      }
    } else {
      setItemIdBusy(itemIdBusy.filter((item) => !itemIdBusy.includes(item)));
    }
  };

  const renderHeader = () => {
    return (
      <div className="list-header">
        <h1 className="title">Inbox</h1>
        <DashboardHeader
          folder="inbox"
          folders={ContextState.dashboardFoldersOverlays}
          rootFolderId={ContextState.dashboardRootFolderOverlays}
          busy={fetchAppBusy}
          items={filtered}
          dashboardType={"controlapps"}
          onLoadItems={() => {
            ContextDispatch({
              type: "DASHBOARD_INBOX_FORCE_RELOAD",
            });
          }}
          onSetItemIdBusy={onSetItemIdBusy}
        />
      </div>
    );
  };

  const renderContent = () => {
    if (fetchAppBusy) {
      return (
        <div className="fetch-busy">
          <IconSpinner />
        </div>
      );
    } else {
      return (
        <DashboardInboxList
          apps={apps}
          filtered={filtered}
          view={ContextState.dashboardView}
          folder="inbox"
          itemIdBusy={itemIdBusy}
          onFilterChanged={onFilterChanged}
          onUpdateItem={onUpdateItem}
          onLoadItems={() => {
            ContextDispatch({
              type: "DASHBOARD_INBOX_FORCE_RELOAD",
            });
          }}
          onSetItemIdBusy={onSetItemIdBusy}
        />
      );
    }
  };

  return (
    <div className="dashboard-myoverlays">
      {renderHeader()}
      <div className="content">{renderContent()}</div>
    </div>
  );
};

export default DashboardInbox;

import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { IconAngleLeft, IconAngleRight } from "../utilities/SvgIcon";

import { Context } from "../stores/store";
import HomeLibraryItem from "./HomeLibraryItem";
import Util from "../utilities/Util";

const HomeFeatured = ({ onFilterChanged }) => {
  const [ContextState] = useContext(Context);

  const navigate = useNavigate();

  const responsive = {
    xllarge: {
      breakpoint: {
        max: 2900,
        min: 2400,
      },
      items: 4,
      partialVisibilityGutter: 40,
    },
    xlarge: {
      breakpoint: {
        max: 2400,
        min: 1900,
      },
      items: 3,
      partialVisibilityGutter: 40,
    },
    large: {
      breakpoint: {
        max: 1900,
        min: 1300,
      },
      items: 2,
      partialVisibilityGutter: 40,
    },
    medium: {
      breakpoint: {
        max: 1300,
        min: 800,
      },
      items: 1,
      partialVisibilityGutter: 40,
    },
    small: {
      breakpoint: {
        max: 800,
        min: 0,
      },
      items: 1,
      partialVisibilityGutter: 40,
    },
  };

  const CustomArrow = ({ onClick, ...rest }) => {
    const { direction } = rest;
    // onMove means if dragging or swiping in progress.
    return (
      <div
        className={"arrow-button " + (direction === "left" ? "left" : "right")}
        onClick={() => onClick()}
      >
        {direction === "left" && <IconAngleLeft />}
        {direction === "right" && <IconAngleRight />}
      </div>
    );
  };

  // get the items with the featured flag
  let featured;
  if (ContextState.subdomain) {
    featured = ContextState.libraryContent.filter((item) => {
      return (
        item.tags &&
        item.tags
          .map((tag) => tag.toLowerCase())
          .includes("uno_" + ContextState.subdomain)
      );
    });

    // if we have less than 6 items then we add the featured items until we reach 6
    // if (featured.length < 6) {
    //   let moreFeatured = ContextState.libraryContent.filter(
    //     (item) => item.featured
    //   );
    //   moreFeatured.sort((a, b) => {
    //     if (a.updated_at < b.updated_at) {
    //       return 1;
    //     }
    //     if (a.updated_at > b.updated_at) {
    //       return -1;
    //     }
    //     return 0;
    //   });

    //   // add the items to the featured list until it is 6 items long
    //   for (let i = 0; i < moreFeatured.length; i++) {
    //     if (featured.length < 6) {
    //       featured.push(moreFeatured[i]);
    //     } else {
    //       break;
    //     }
    //   }
    // }
  } else {
    // get all the featured items
    featured = ContextState.libraryContent.filter((item) => item.featured);
    featured.sort((a, b) => {
      if (a.updated_at < b.updated_at) {
        return 1;
      }
      if (a.updated_at > b.updated_at) {
        return -1;
      }
      return 0;
    });
  }

  const onSelectItem = (item) => {
    let url = Util.getLibraryItemPath(item);
    if (url) {
      onFilterChanged("clear");
      navigate("/library/" + url);
    }
  };

  const onSelectFilter = (e, filter, value) => {
    onFilterChanged("set", filter, value);
    navigate("/library");
    e.stopPropagation();
  };

  // if we have nothing to display then we skip the featured list
  if (featured.length === 0) {
    return null;
  }

  return (
    <div className="home-featured">
      <h2 className="title">Featured</h2>
      <Carousel
        swipeable={true}
        draggable={false}
        responsive={responsive}
        centerMode={true}
        partialVisible={false}
        infinite={true}
        customRightArrow={<CustomArrow direction="right" />}
        customLeftArrow={<CustomArrow direction="left" />}
        autoPlay={true}
        autoPlaySpeed={5000}
        containerClass="carousel"
      >
        {featured.map((item, index) => (
          <HomeLibraryItem
            key={index}
            item={item}
            onSelectItem={onSelectItem}
            onSelectFilter={onSelectFilter}
          />
        ))}
      </Carousel>
      <div className="shadow-left"></div>
      <div className="shadow-right"></div>
    </div>
  );
};

export default HomeFeatured;

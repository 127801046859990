const Config = {
  singularUrl: "https://app.overlays.uno",
  paymentAPI: "https://app.overlays.uno/apiv2/payments/",
  checkoutId: "pk_live_pyiKgFBPEvvwbXc0M10bV88Y",
  localStorageFileName: "UnoPortal",
  unoLogoMedium: "/images/UnoLogoMedium.png",
  unoLogoTiny: "/images/UnoLogoTiny.png",
  unoCustomAdsUrl:
    "https://assets.singular.live/7072b13f9e20b98034f48d6202400ff9/jsons/2TZToUMX36BlSDuW7iI2ab.json",
  partnerPortalSubDomains: {
    aws: "https://assets.singular.live/7072b13f9e20b98034f48d6202400ff9/jsons/5gwvcIaC2akpoZN291lI0x.json",
    cuez: "https://assets.singular.live/7072b13f9e20b98034f48d6202400ff9/jsons/69Cp08tHbZjwNEZsMgoUCN.json",
    ecamm: "https://assets.singular.live/7072b13f9e20b98034f48d6202400ff9/jsons/6hmmziGeZJ7i5V9Lxt1Bsp.json",
    epiphan:
      "https://assets.singular.live/7072b13f9e20b98034f48d6202400ff9/jsons/4LbpSa8WLLsB5acGArcaOH.json",
    gv: "https://assets.singular.live/7072b13f9e20b98034f48d6202400ff9/jsons/1P8djOwaIYEVpBW7g86zg7.json",
    liveu:
      "https://assets.singular.live/7072b13f9e20b98034f48d6202400ff9/jsons/6Aueul06iXY2viwquW9cuj.json",
    mavis:
      "https://assets.singular.live/7072b13f9e20b98034f48d6202400ff9/jsons/6FmjWQDhIHrAkdCCmV6DoL.json",
    magewell:
      "https://assets.singular.live/7072b13f9e20b98034f48d6202400ff9/jsons/5gz90oquolHCCnXqu5VN1a.json",
    newtek:
      "https://assets.singular.live/7072b13f9e20b98034f48d6202400ff9/jsons/3Lfa6sOa9MhuRQYLTbOE5c.json",
    podcasts:
      "https://assets.singular.live/7072b13f9e20b98034f48d6202400ff9/jsons/23Sg1EGms8QLBsHqsfKFP8.json",
    ptzoptics: 
      "https://assets.singular.live/7072b13f9e20b98034f48d6202400ff9/jsons/4OtUijyhzrCzOCUCexvpqO.json",
    scorebird:
      "https://assets.singular.live/7072b13f9e20b98034f48d6202400ff9/jsons/7xxb2LgVkb0NIAUzu0ROA8.json",
    sonym2:
      "https://assets.singular.live/7072b13f9e20b98034f48d6202400ff9/jsons/377KQ274zOIahdYLqScucT.json",
    sports:
      "https://assets.singular.live/7072b13f9e20b98034f48d6202400ff9/jsons/0DsPVOa2GwEVETCgZcfSSY.json",
    test: "https://assets.singular.live/7072b13f9e20b98034f48d6202400ff9/jsons/66AyayRgoLx8tOB9TlFAVn.json",
    tvu: "https://assets.singular.live/7072b13f9e20b98034f48d6202400ff9/jsons/488kGqlZgQGTFmpWvvAhcb.json",
    uno: "https://assets.singular.live/7072b13f9e20b98034f48d6202400ff9/jsons/3eEJQYLXYIwIJDg6DCpr3E.json",
    zixi: "https://assets.singular.live/7072b13f9e20b98034f48d6202400ff9/jsons/2qpkD6gcEMYJBu4RyhsqzD.json",
  },
  partnerPortalConfigDefault: {
    title: "Overlays Galore, Zip Files No More!",
    tagline: "Free Real-Time Stream Overlays",
    welcome: {
      link: "https://discord.gg/g6rJYsXua9",
      imageUrl: "images/UnoPromoDiscord.jpg",
    },
    promoBackground: "images/HomePromoBackground.jpg",
    promoLoop: [
      {
        title: "uno Social Media Loopers",
        message: "Display your social media handles in an animated loop",
        imageUrl:
          "https://image.singular.live/7072b13f9e20b98034f48d6202400ff9/images/2M14970U6oenD3jczQqdJ1_w485h272.png",
        imageAlt: "promo media looper",
        link: "/library/494-Social-Looper-Bug-3---Sociable",
        button: "Explore Overlays",
      },
      {
        title: "uno in OBS Studio",
        message: "Use a browser source into OBS, with dockable controls",
        imageUrl:
          "https://image.singular.live/7072b13f9e20b98034f48d6202400ff9/images/4G3c15goRBucpqWCOFfXNN_w485h272.png",
        imageAlt: "promo obs",
        link: "https://resources.overlays.uno/obs-dock",
        button: "Learn More",
      },
      {
        title: "uno Stream Deck Plugin",
        message: "All your overlays at your fingertips, using Stream Deck",
        imageUrl:
          "https://image.singular.live/7072b13f9e20b98034f48d6202400ff9/images/5Cc2u0OHka21N8TunmgerV_w485h272.png",
        imageAlt: "promo streamdeck",
        link: "https://resources.overlays.uno/streamdeck",
        button: "Learn More",
      },
      {
        title: "uno Animated Lower Thirds",
        message: "Customize your lower thirds with ease",
        imageUrl:
          "https://image.singular.live/7072b13f9e20b98034f48d6202400ff9/images/2E51ZHRG5uDuFUEKYk0gFR_w1280h720.jpg",
        imageAlt: "promo lower third",
        link: "/library/544-Lower-Third---Luck",
        button: "Explore Overlays",
      },
      {
        title: "uno Stream Countdowns",
        message: "Set the timer and go, it's that simple",
        imageUrl:
          "https://image.singular.live/7072b13f9e20b98034f48d6202400ff9/images/2scF9glYpHoyfWPpLOK1gv_w485h272.png",
        imageAlt: "promo countdown",
        link: "/library/282-Countdown---Nitrogen",
        button: "Explore Overlays",
      },
      {
        title: "uno RSS News Tickers",
        message: "Select from dozens of RSS news feeds",
        imageUrl:
          "https://image.singular.live/7072b13f9e20b98034f48d6202400ff9/images/7wqEWOmiGbfojivUdzWZ90_w485h272.png",
        imageAlt: "promo ticker",
        link: "/library/557-RSS-News-Ticker---Prime",
        button: "Explore Overlays",
      },
      {
        title: "uno Weather Bugs",
        message: "Search a location, then auto loop weather conditions",
        imageUrl:
          "https://image.singular.live/7072b13f9e20b98034f48d6202400ff9/images/71Z8xRvhfVyHPs7Eun5BR9_w485h272.png",
        imageAlt: "promo weather",
        link: "/library/317-Weather-Bug---Breeze",
        button: "Explore Overlays",
      },
    ],
  },
};

export default Config;

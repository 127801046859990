import { useParams } from "react-router-dom";

const ResetPasswordHelper = ({ onSetPasswordResetToken }) => {
  const { resetToken } = useParams();

  onSetPasswordResetToken(resetToken);

  return <div></div>;
};

export default ResetPasswordHelper;

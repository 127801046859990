import Util from "../utilities/Util";
import {
  IconFilterFunction,
  IconFilterCategory,
  IconFilterType,
  IconFilterTheme,
  IconFilterLayout,
  IconFilterDataSource,
} from "../utilities/SvgIcon";

const Tags = ({ item, size, onSelectFilter }) => {
  const handleClick = (e, type, value) => {
    if (onSelectFilter) {
      onSelectFilter(e, type, value);
    }
  };
  if (!item) {
    return <div className="tags"></div>;
  }

  let tagsClassName = "tags";
  if (size === "small") {
    tagsClassName += " small";
  }
  if (onSelectFilter) {
    tagsClassName += " can-click";
  }

  const variants = Util.getLibraryItemDisplayVariants(item);

  const renderVariants = () => {
    return (
      <>
        {["Landscape", "Portrait", "Square", "Responsive"].map((layout) => {
          if (Util.displayVariantHasLayout(variants, layout)) {
            return (
              <div
                className="tag"
                onClick={(e) => handleClick(e, "tag_layout", layout)}
              >
                <IconFilterLayout />
                {layout}
              </div>
            );
          } else {
            return null;
          }
        })}
      </>
    );
  };

  return (
    <div className={tagsClassName}>
      {item.tag_type && (
        <div
          className="tag"
          onClick={(e) => handleClick(e, "tag_type", item.tag_type)}
        >
          <IconFilterType />
          {item.tag_type}
        </div>
      )}
      {item.tag_function && (
        <div
          className="tag"
          onClick={(e) => handleClick(e, "tag_function", item.tag_function)}
        >
          <IconFilterFunction />
          {item.tag_function}
        </div>
      )}
      {item.tag_category && (
        <div
          className="tag"
          onClick={(e) => handleClick(e, "tag_category", item.tag_category)}
        >
          <IconFilterCategory />
          {item.tag_category}
        </div>
      )}
      {item.tag_theme && (
        <div
          className="tag"
          onClick={(e) => handleClick(e, "tag_theme", item.tag_theme)}
        >
          <IconFilterTheme />
          {item.tag_theme}
        </div>
      )}
      {renderVariants()}
      {item.tag_datasource && (
        <div
          className="tag"
          onClick={(e) => handleClick(e, "tag_datasource", item.tag_datasource)}
        >
          <IconFilterDataSource />
          {item.tag_datasource}
        </div>
      )}
    </div>
  );
};

export default Tags;

import React from "react";
import Util from "../utilities/Util";

import FolderMenu from "./FolderMenu";

import { IconAll } from "../utilities/SvgIcon";

const Folder = ({
  folders,
  folderId,
  rootFolderId,
  dashboardType,
  disabled,
  onNavigate,
  onSetFolder,
}) => {
  if (!folders || !folders.length) {
    return null;
  }

  const folderName = Util.getFolderName(folders, folderId);

  if (disabled) {
    if (!folderName) {
      return (
        <div className="folder">
          <span className="name"></span>
        </div>
      );
    } else {
      return (
        <div className="folder">
          <IconAll className="icon" />
          <span className="name">{folderName}</span>
        </div>
      );
    }
  }

  // if no folder is assigned to the overlay, show the "Add to a folder" option
  if (!folderName) {
    return (
      <div
        className="folder enabled"
        onDoubleClick={(e) => e.stopPropagation()}
      >
        <FolderMenu
          folders={folders}
          rootFolderId={rootFolderId}
          dashboardType={dashboardType}
          message="Move to folder"
          onSelectFolder={onSetFolder}
        />
      </div>
    );
  } else {
    return (
      <div
        className="folder enabled"
        onDoubleClick={(e) => e.stopPropagation()}
      >
        <FolderMenu
          folders={folders}
          rootFolderId={rootFolderId}
          dashboardType={dashboardType}
          onSelectFolder={onSetFolder}
        />
        <div
          className="name"
          onClick={(e) => onNavigate(folderId)}
          disabled={disabled}
        >
          {folderName}
        </div>
      </div>
    );
  }
};

export default Folder;

import { toast } from "react-toastify";
import Moment from "moment";
import { IconOK, IconError } from "./SvgIcon";
import { useRef, useEffect } from "react";

let g_isIOS = undefined;

const exportedObject = {
  addNotification: function (message, type, duration) {
    let icon = <IconOK fill="#fff" />;
    if (type === "error") {
      icon = <IconError fill="#fff" />;
    }
    toast(message, {
      type: type || "success",
      icon: icon,
      position: "top-center",
      autoClose: duration || 5000,
      closeOnClick: true,
      pauseOnHover: true,
    });
  },

  processItemArray: function (items) {
    items.map((item) => {
      // parse the tags
      let tags = [];
      try {
        tags = JSON.parse(item.tags);
      } catch (error) {}
      item.tags = tags;

      // parse the content string
      let content = {};
      try {
        content = JSON.parse(item.content);
      } catch (error) {}
      item.content = content;
      return undefined;
    });

    // make sure the data in the packages is correct
    return items.filter((item) => {
      if (
        item.content &&
        item.content.type &&
        item.content.type === "app" &&
        item.content.content &&
        item.content.content.length &&
        item.content.content[0].element &&
        item.content.content[0].element.id
      ) {
        return true;
      } else {
        return false;
      }
    });
  },

  getLibraryItemPath: function (item) {
    // Replace all pipe with dash, remove space
    return item.name
      ? item.id +
          "-" +
          item.name
            .trim()
            .replace(/\|/g, "-")
            .replace(new RegExp(" ", "g"), "-")
      : item.id;
  },

  getLibraryNewReleaseDaysLeft: function (item) {
    if (!item || !item.new_release) {
      return 0;
    }

    let numberOfDays = Moment(item.new_release).diff(Date.now(), "days") + 1;
    if (numberOfDays > 0) {
      return numberOfDays;
    } else {
      return 0;
    }
  },

  getLibraryItemDisplayVariants: function (item) {
    if (item.content && item.content.content && item.content.content.length) {
      const displayVariants = item.content.content[0].displayVariants;
      // if we have a display variant we check if the layout is in the list
      if (
        displayVariants &&
        displayVariants.variants &&
        displayVariants.variants.length
      ) {
        return displayVariants.variants;
      }
    }
    return null;
  },

  displayVariantHasLayout: function (variants, layout) {
    if (!variants || !variants.length) {
      return false;
    }

    let found = false;
    variants.forEach((variant) => {
      const resolution = variant.resolution;
      switch (String(layout).toLowerCase()) {
        case "landscape":
          if (
            resolution === "1280x720" ||
            resolution === "1920x1080" ||
            resolution === "3840x2160"
          ) {
            found = true;
          }
          break;
        case "portrait":
          if (
            resolution === "720x1280" ||
            resolution === "1080x1920" ||
            resolution === "2160x3840"
          ) {
            found = true;
          }
          break;
        case "Square":
          if (resolution === "1080x1080") {
            found = true;
          }
          break;
        case "responsive":
          if (resolution === "Responsive") {
            found = true;
          }
          break;
        default:
          break;
      }
    });
    return found;
  },

  getFolderName: function (folderList, folder) {
    if (folderList && folderList.length > 0) {
      const f = folderList.find((item) => item.id === folder);
      if (f) {
        return f.name;
      }
    }
    return "";
  },

  getSubscriptionPlan: function (userInfo) {
    // if the brand is singular we shouldn't show the uno subscription plans
    if (userInfo && userInfo.brand === "singular") {
      return "singular";
    }

    let subscription = "";
    if (userInfo) {
      subscription = userInfo.subscriptionPlan;
    }

    // free subscription
    if (!subscription || subscription === "free") {
      return "free";
    }

    // these are paid levels for uno
    if (subscription === "unoplus" || subscription === "unoelite") {
      return subscription;
    }

    // these are the paid levels for singular
    return "singular";
  },

  getHasInbox: function (userInfo) {
    if (userInfo) {
      const subscription = this.getSubscriptionPlan(userInfo);
      if (subscription === "unoelite" || subscription === "singular") {
        return true;
      }
    }
    return false;
  },

  fixImageUrl: function (url) {
    // http://image.singular.live/fit-in/150x150/7072b13f9e20b98034f48d6202400ff9/images/0jlRKXB9cqZWmGbdsPv2Y7.PNG
    if (url) {
      return url.replace(
        "fit-in/150x150/",
        "fit-in/450x450/filters:format(webp)/"
      );
    } else {
      // an empty image
      return "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
    }
  },

  fixOutputUrl: function (url) {
    url = String(url);

    // find "/output/"
    let index = url.indexOf("/output/");
    if (index !== -1 && index + 30 < url.length) {
      // the 30 is 8 for /output/ and 22 or the token
      // we found index and now check if there is a / character after 30 characters
      if (url.charAt(index + 30) === "/") {
        // if it is then forget the rest of the URL
        return url.substring(0, index + 30);
      }
    }
    // return the URL. It will work, but is not cleaed up
    return url;
  },

  addVideoUrlSuffix: function (url, suffix, ext) {
    let name = "",
      extension = "";
    let index = url.lastIndexOf(".");
    if (index !== -1) {
      if (ext) {
        extension = "." + ext;
      } else {
        extension = url.substring(index, url.length);
      }
      name = url.substring(0, index, url.length);
      if (suffix) {
        return name + "-" + suffix + extension;
      } else {
        return name + extension;
      }
    } else {
      return url + "-" + suffix;
    }
  },

  addImageFitIn: function (url, width, height) {
    if (!url || !width || !height) {
      return url;
    }

    // if the url is from singular.live then add the fit-in
    let index = url.indexOf("image.singular.live");
    if (index !== -1) {
      return url.replace(
        "image.singular.live",
        "image.singular.live/fit-in/" +
          width +
          "x" +
          height +
          "/filters:format(webp)"
      );
    } else {
      return url;
    }
  },

  isIOS: function () {
    if (g_isIOS === undefined) {
      g_isIOS =
        [
          "iPad Simulator",
          "iPhone Simulator",
          "iPod Simulator",
          "iPad",
          "iPhone",
          "iPod",
        ].includes(navigator.platform) ||
        (navigator.userAgent.includes("Mac") && "ontouchend" in document);
    }
    return g_isIOS;
  },

  resetAllTokens: function (ContextDispatch) {
    ContextDispatch({
      type: "SET_UNO_ACCESS_TOKEN",
      payload: "",
    });
    ContextDispatch({
      type: "SET_UNO_REFRESH_TOKEN",
      payload: "",
    });
    ContextDispatch({
      type: "SET_AUTHENTICATION_STATUS",
      payload: "none",
    });
    ContextDispatch({
      type: "SET_USER_INFO",
      payload: "",
    });
  },

  getFetchHeaders: function (ContextState) {
    return {
      "Content-Type": "application/json",
      Authorization: "Bearer " + ContextState.unoAccessToken,
    };
  },

  handleFetchError: function (result, ContextDispatch, navigate, duration) {
    if (result && result.error) {
      if (result.error.code === 401) {
        this.resetAllTokens(ContextDispatch);
        if (navigate) {
          navigate("/login");
        }
      } else {
        this.addNotification(result.error.message, "error", duration);
      }
      throw result.error;
    }
  },

  openUrl: function (url) {
    // we are in tryme mode. open the app UI
    let result;
    try {
      result = window.open(url, "_blank");
      if (result) {
        result.focus();
      }
    } catch (error) {}
    if (result === undefined) {
      this.addNotification(
        "Cannot open new browser tab. Please disable 'Block Pop-ups' in your browser",
        "error",
        15000
      );
    }
  },

  registerToVisibilityChangeAndReload: function () {
    // register a listener to visibilitychange
    document.addEventListener("visibilitychange", () => {
      // reload the page
      if (!document.hidden) {
        window.location.reload();
      }
    });
  },

  validateEmail: (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  },

  getUrlSearchParameter: (name) => {
    const searchParams = new URLSearchParams(window.location.search);
    const r = searchParams.get(name);
    if (r === null) {
      return "";
    } else {
      return r;
    }
  },

  humanFileSize: function (bytes) {
    if (!bytes) {
      bytes = 0;
    }
    if (Math.abs(bytes) < 1024) {
      return bytes + " B";
    }
    var units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    var divisor = [1024, 1024, 1000, 1000, 1000, 1000, 1000, 1000, 1000];
    var u = -1;
    do {
      u++;
      bytes /= divisor[u];
    } while (Math.abs(bytes) >= divisor[u + 1] && u < units.length - 1);
    return bytes.toFixed(1) + " " + units[u];
  },

  numberWithCommas: function (x) {
    if (!x) {
      x = 0;
    }
    if (typeof x === "number" || typeof x === "string") {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return "0";
    }
  },

  shortNumber: function (x) {
    if (!x) {
      x = 0;
    }
    if (x > 1000000) {
      return (x / 1000000).toFixed(1) + " m";
    }
    if (x > 1000) {
      return (x / 1000).toFixed(1) + " k";
    }
    return x;
  },

  useEffectOnce: (callback, when) => {
    const hasRunOnce = useRef(false);
    useEffect(() => {
      if (when && !hasRunOnce.current) {
        callback();
        hasRunOnce.current = true;
      }
    }, [callback, when]);
  },
};

export default exportedObject;

import { useState } from "react";

import { IconEye, IconEyeSlash } from "../utilities/SvgIcon";

const PasswordInput = ({ onChange, onKeyDown, onPasswordOk, minLength }) => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  // we enter the password
  const handlePasswordChanged = (e) => {
    let value = e.target.value;

    setPassword(value);
    onChange(value);

    if (value.length === 0 || (minLength && value.length < minLength)) {
      if (onPasswordOk) {
        onPasswordOk(false);
      }
      return;
    }
    if (onPasswordOk) {
      onPasswordOk(true);
    }
  };

  const showMinLength = (minLength && password.length < minLength) === true;

  return (
    <div className="label-input">
      <div className="label">Password</div>
      <input
        type={showPassword ? "text" : "password"}
        className="input"
        name="password"
        value={password}
        onChange={handlePasswordChanged}
        onKeyDown={(e) => {
          if (onKeyDown) {
            onKeyDown(e);
          }
        }}
      />
      <div
        className="password-reveal"
        onClick={() => {
          setShowPassword(!showPassword);
        }}
      >
        {!showPassword && <IconEyeSlash fill="var(--sl-white)" />}
        {showPassword && <IconEye fill="var(--sl-white)" />}
      </div>
      {showMinLength && (
        <div className="password-strength-label">
          Password must be at least {minLength} characters
        </div>
      )}
    </div>
  );
};

export default PasswordInput;

import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Context } from "../stores/store";

import Config from "../stores/Config";
import Util from "../utilities/Util";

import { IconSpinner, IconClose } from "../utilities/SvgIcon";

const VerifyEmail = ({ onClose }) => {
  const [busy, setBusy] = useState(false);

  const [ContextState, ContextDispatch] = useContext(Context);

  const navigate = useNavigate();

  const onButtonClick = (event) => {
    setBusy(true);

    fetch(Config.singularUrl + "/apiv2/users/resendverify?brand=uno", {
      method: "POST",
      body: JSON.stringify({
        email: ContextState.userInfo.email,
      }),
      headers: Util.getFetchHeaders(ContextState),
    })
      .then((res) => res.json())
      .then((result) => {
        setBusy(false);
        Util.handleFetchError(result, ContextDispatch, navigate);
        Util.addNotification("An email has been sent to your email address");
      })
      .catch((error) => {
        setBusy(false);
      });
  };

  useEffect(() => {
    if (ContextState.authenticationStatus !== "notverified") {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ContextState.authenticationStatus]);

  let email;
  if (ContextState.userInfo && ContextState.userInfo.email) {
    email = ContextState.userInfo.email;
  } else {
    email = "No email address found";
  }

  return (
    <div className="content">
      <div className="verifyemail-header">
        <img className="logo" src={Config.unoLogoMedium} alt="" />
        <span className="close" onClick={() => onClose()}>
          <IconClose />
        </span>
      </div>
      <div className="title">Verify email</div>

      <div className="form">
        <div className="message">
          We have sent a verification email to {email}. You have to click the
          verification link in that email. <br />
          <br />
          Please check the Spam / Junk folder. If you cannot find the
          verification email click the button below to resend.
        </div>

        <button className="button-brand" onClick={onButtonClick}>
          {!busy && <span>Resend verification email</span>}
          {busy && <IconSpinner />}
        </button>
      </div>
    </div>
  );
};

export default VerifyEmail;

import React, { useContext } from "react";

import { Context } from "../stores/store";

import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";

import { IconAll, IconNewOverlay } from "../utilities/SvgIcon";

const FolderMenu = ({
  folders,
  rootFolderId,
  dashboardType,
  message,
  onSelectFolder,
  onCreateFolder,
}) => {
  const [, ContextDispatch] = useContext(Context);

  // translate the folder ID into a folder name
  return (
    <div className="folder-menu">
      <Menu
        menuButton={
          <MenuButton className="button">
            <>
              <IconAll className="icon" />
              {message && <span className="message">{message}</span>}
            </>
          </MenuButton>
        }
        transition
        align="start"
        menuClassName="menu-background"
        offsetY={2}
      >
        {rootFolderId && (
          <MenuItem
            key="no-folder"
            onClick={() => {
              onSelectFolder(rootFolderId);
            }}
            className="menu-item"
          >
            <div className="name no-folder">Root folder</div>
          </MenuItem>
        )}

        {folders.map((item, index) => {
          return (
            <MenuItem
              key={index}
              onClick={() => {
                onSelectFolder(item.id);
              }}
              className="menu-item"
            >
              <div className="name">{item.name}</div>
            </MenuItem>
          );
        })}

        <MenuItem
          key="create-folder"
          onClick={() => {
            ContextDispatch({
              type: "SET_DASHBOARD_CREATE_FOLDER",
              payload: dashboardType,
            });
          }}
          className="menu-item"
        >
          <div className="name create-folder">
            <IconNewOverlay className="icon" />
            Create Folder
          </div>
        </MenuItem>
      </Menu>
    </div>
  );
};

export default FolderMenu;

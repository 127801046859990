import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Context } from "../stores/store";
import Config from "../stores/Config";
import Util from "../utilities/Util";

import PasswordInput from "./PasswordInput";

import { IconSpinner, IconClose } from "../utilities/SvgIcon";

const ResetPassword = ({ passwordResetToken, onClose }) => {
  const [password, setPassword] = useState("");
  const [passwordOK, setPasswordOK] = useState(false);
  const [busy, setBusy] = useState(false);

  const [, ContextDispatch] = useContext(Context);

  const navigate = useNavigate();

  const onButtonClick = (event) => {
    setBusy(true);

    fetch(Config.singularUrl + "/apiv2/users/recoverpassword", {
      method: "POST",
      body: JSON.stringify({
        password: password,
        token: passwordResetToken,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setBusy(false);
        if (data && data.success) {
          Util.addNotification("Your password has been reset successfully");
          Util.resetAllTokens(ContextDispatch);
          navigate("/login");
        } else {
          if (data.error) {
            Util.addNotification(data.error.message, "error");
          } else {
            Util.addNotification(
              "Error while processing authentication",
              "error"
            );
          }
        }
      })
      .catch((error) => {
        Util.addNotification(
          "Server connection error, please try again",
          "error"
        );
        setBusy(false);
      });
  };

  return (
    <div className="content">
      <div className="forgotpassword-header">
        <img className="logo" src={Config.unoLogoMedium} alt="" />
        <span className="close" onClick={() => onClose()}>
          <IconClose />
        </span>
      </div>
      <div className="title">Set new password</div>

      <div className="form">
        <PasswordInput
          onChange={(value) => setPassword(value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onButtonClick();
            }
          }}
          onPasswordOk={(v) => setPasswordOK(v)}
          minLength={5}
        />
        <div className="offset"></div>
        <button
          className="button-brand"
          onClick={onButtonClick}
          disabled={!passwordOK}
        >
          {!busy && <span>Submit</span>}
          {busy && <IconSpinner />}
        </button>
      </div>
    </div>
  );
};

export default ResetPassword;

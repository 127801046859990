import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Context } from "../stores/store";
import Config from "../stores/Config";
import Util from "../utilities/Util";

import {
  IconSpinner,
  IconThemeDark,
  IconThemeLight,
  IconCheckOn,
  IconCheckOff,
} from "../utilities/SvgIcon";

const Account = ({ onShowDialog }) => {
  const [ContextState, ContextDispatch] = useContext(Context);

  const [nameBusy, setNameBusy] = useState(false);
  const [subscribeBusy, setSubscribeBusy] = useState(false);

  const [passwordBusy, setPasswordBusy] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (ContextState.authenticationStatus !== "ok" || !ContextState.userInfo) {
      navigate("/login");
    }
  }, [ContextState.authenticationStatus, ContextState.userInfo, navigate]);

  // set the initial values
  // these variables should be stored in useRef, but I think this works without, because there
  // is only one instance of the account UI and the data is kind of gobal anyways.
  let initEmail = "",
    initName = "",
    initSubscribe = false;
  if (ContextState.userInfo) {
    initEmail = ContextState.userInfo.email;
    initName = ContextState.userInfo.name;
    initSubscribe = ContextState.userInfo.subscribeUnoNewsletter;
  }
  const [name, setName] = useState(initName);
  const [email, setEmail] = useState(initEmail);
  const [subscribe, setSubscribe] = useState(initSubscribe);

  const changePassword = () => {
    setPasswordBusy(true);
    fetch(Config.singularUrl + "/apiv2/users/forgotpassword", {
      method: "POST",
      body: JSON.stringify({
        email: email,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setPasswordBusy(false);
        Util.handleFetchError(result, ContextDispatch, navigate);
        Util.addNotification("An email has been sent to your email address");
      })
      .catch((error) => {
        setPasswordBusy(false);
      });
  };

  const onChangeName = () => {
    if (name === initName) {
      return;
    }

    setNameBusy(true);

    fetch(Config.singularUrl + "/apiv2/users/me", {
      method: "PATCH",
      body: JSON.stringify({
        name: name,
      }),
      headers: Util.getFetchHeaders(ContextState),
    })
      .then((res) => res.json())
      .then((result) => {
        Util.handleFetchError(result, ContextDispatch, navigate);

        setNameBusy(false);

        // set the new user name
        let newUserInfo = JSON.parse(JSON.stringify(ContextState.userInfo));
        newUserInfo.name = name;
        ContextDispatch({
          type: "SET_USER_INFO",
          payload: newUserInfo,
        });

        initName = name;
        Util.addNotification("Your user name has been updated");
      })
      .catch((error) => {
        setName(initName);
        setNameBusy(false);
      });
  };

  const onChangeSubscribe = (value) => {
    // remember the old value so we can reset it if the save goes wrong
    let oldSubscribe = subscribe;

    // update the UI
    setSubscribe(value);

    // switch to busy mode
    setSubscribeBusy(true);

    // do the call
    fetch(Config.singularUrl + "/apiv2/users/me", {
      method: "PATCH",
      body: JSON.stringify({
        subscribeUnoNewsletter: value,
      }),
      headers: Util.getFetchHeaders(ContextState),
    })
      .then((res) => res.json())
      .then((result) => {
        Util.handleFetchError(result, ContextDispatch, navigate);

        setSubscribeBusy(false);

        // set the new user name
        let newUserInfo = JSON.parse(JSON.stringify(ContextState.userInfo));
        newUserInfo.subscribeUnoNewsletter = value;
        ContextDispatch({
          type: "SET_USER_INFO",
          payload: newUserInfo,
        });

        Util.addNotification("Update subscription has been updated");
      })
      .catch((error) => {
        setSubscribe(oldSubscribe);
        setSubscribeBusy(false);
      });
  };

  return (
    <div className="account">
      <div className="account-header">Account Settings</div>
      <div className="line">
        <div className="title">User Name</div>
        <input
          type="text"
          className="input"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          onBlur={(e) => {
            onChangeName();
          }}
          onKeyDown={(e) => {
            if (e.key === "Escape") {
              setName(initName);
              return;
            }
            if (e.key === "Enter") {
              onChangeName();
            }
          }}
          disabled={nameBusy}
        />
        {nameBusy && (
          <div className="autosaving">
            <IconSpinner className="busy" />
            <span>Autosaving</span>
          </div>
        )}
      </div>
      <div className="line">
        <div className="title">Email</div>
        <input
          type="text"
          className="input off"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          disabled={true}
        />
      </div>

      <div className="line align-top">
        <div className="title">Theme</div>
        <div className="selection">
          <div
            className={
              "button-group " +
              (ContextState.theme === "light" ? "selected" : "")
            }
          >
            <button
              className="button light"
              onClick={() => {
                ContextDispatch({
                  type: "SET_THEME",
                  payload: "light",
                });
              }}
            >
              <IconThemeLight />
            </button>
            <div className="label">Light</div>
          </div>
          <div
            className={
              "button-group " +
              (ContextState.theme === "dark" ? "selected" : "")
            }
          >
            <button
              className="button dark"
              onClick={() => {
                ContextDispatch({
                  type: "SET_THEME",
                  payload: "dark",
                });
              }}
            >
              <IconThemeDark />
            </button>
            <div className="label">Dark</div>
          </div>
        </div>
      </div>

      <div className="line">
        <div className="title"></div>
        <div
          className="toggle"
          onClick={() => {
            if (!subscribeBusy) {
              onChangeSubscribe(!subscribe);
            }
          }}
        >
          {subscribe && <IconCheckOn />}
          {!subscribe && <IconCheckOff />}
        </div>
        <div className="text">Subscribe for email updates</div>
        {subscribeBusy && (
          <div className="autosaving">
            <IconSpinner className="busy" />
            <span>Autosaving</span>
          </div>
        )}
      </div>

      <div className="button-line">
        <div
          className="button"
          onClick={changePassword}
          disabled={passwordBusy}
        >
          {!passwordBusy && <span>Reset Password</span>}
          {passwordBusy && <IconSpinner />}
        </div>
        <div
          className="button delete"
          onClick={() => onShowDialog("deleteaccount")}
        >
          Delete Account
        </div>
      </div>
    </div>
  );
};

export default Account;

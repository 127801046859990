import React, { useState } from "react";

import Util from "../utilities/Util";
import Tags from "../features/Tags";

const HomeLibraryItem = ({ item, size, onSelectItem, onSelectFilter }) => {
  const [cardHover, setCardHover] = useState(false);

  let firstContent = {};
  if (item && item.content && item.content.content && item.content.content.length) {
    firstContent = item.content.content[0];
  }

  let video;
  if (cardHover && firstContent.videoURL) {
    video = (
      <video
        key={firstContent.videoURL}
        className="video"
        autoPlay
        muted
        loop
        playsInline
      >
        {Util.isIOS() === false && (
          <source
            src={Util.addVideoUrlSuffix(firstContent.videoURL, "small")}
            type="video/webm"
          />
        )}
        {Util.isIOS() === false && (
          <source src={firstContent.videoURL} type="video/webm" />
        )}
        <source
          src={Util.addVideoUrlSuffix(firstContent.videoURL, "small", "mp4")}
          type="video/mp4"
        />
        <source
          src={Util.addVideoUrlSuffix(firstContent.videoURL, "", "mp4")}
          type="video/mp4"
        />
      </video>
    );
  }

  let marker;
  if (item.marker) {
    marker = <img className="marker" src={item.marker} alt="marker" />;
  }

  let image;
  let thumbnail = Util.addImageFitIn(firstContent.imageURL, 720, 360);
  if (thumbnail) {
    image = <img className="image" src={thumbnail} alt="thumbnail" />;
  }

  return (
    <div
      className={"home-library-item " + (size === "small" ? "small" : "")}
      onClick={() => {
        if (onSelectFilter) {
          onSelectItem(item);
        }
      }}
    >
      <div className="item-content">
        <div
          className="package"
          key={item.id}
          onMouseEnter={() => setCardHover(true)}
          onMouseLeave={() => setCardHover(false)}
        >
          {marker}
          {image}
          {video}
        </div>
      </div>
      <div className="metadata">
        <div className="name-group">
          <div className="name">{item.name}</div>
          {Util.getLibraryNewReleaseDaysLeft(item) !== 0 && (
            <div className="new">NEW</div>
          )}
        </div>
        <Tags item={item} size={size} onSelectFilter={onSelectFilter} />
      </div>
    </div>
  );
};

export default HomeLibraryItem;

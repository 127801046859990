import Util from "../utilities/Util";

import { useItemProgressListener, useAbortItem } from "@rpldy/uploady";

import { IconClose, IconAlert, IconSpinner } from "../utilities/SvgIcon";

const UploadImageItem = ({ item, onRemoveItem }) => {
  const abortItem = useAbortItem();

  const UploadProgress = () => {
    let { completed } = useItemProgressListener(item.id) || { completed: 0 };
    if (completed > item.completed) {
      item.completed = completed;
    }
    completed = item.completed;

    let completedText;
    if (item.status === "creating") {
      completedText = "Creating image";
    } else if (item.status === "done") {
      completedText = "Done";
    } else {
      completedText = completed.toFixed(0) + "%";
    }

    return (
      <>
        <div className="progress">
          <div
            className={"bar" + (completed === 100 ? " complete" : "")}
            style={{ width: `${completed}%` }}
          ></div>
        </div>
        <div className="progress-info">
          {completedText}
          <div className="size">{Util.humanFileSize(item.file.size)}</div>
        </div>
      </>
    );
  };

  const ErrorMessage = () => {
    return (
      <>
        <div className="progress-error">
          {item.message || <span>&nbsp;</span>}
        </div>
        <div className="progress-error-info">
          Error
          <div className="size">{Util.humanFileSize(item.file.size)}</div>
        </div>
      </>
    );
  };

  const renderIcon = () => {
    if (item.status === "error") {
      return (
        <div className="icon">
          <IconAlert fill="var(--sl-action-red)" />
        </div>
      );
    } else if (item.status === "init") {
      return (
        <div className="icon">
          <IconSpinner />
        </div>
      );
    } else if (item.preview) {
      return <img className="icon" src={item.preview.dataUrl} alt="preview" />;
    } else {
      return <div className="icon">&nbsp;</div>;
    }
  };

  return (
    <div className="upload-image-item">
      <div className="left">{renderIcon()}</div>
      <div className="right">
        <div className="line">
          <div className="name">{item.file.name}</div>
          <div
            className="close"
            onClick={() => {
              abortItem(item.id);
              onRemoveItem(item.id);
            }}
          >
            <IconClose />
          </div>
        </div>
        {item.status === "error" ? <ErrorMessage /> : <UploadProgress />}
      </div>
    </div>
  );
};

export default UploadImageItem;

import React, { useEffect, useContext } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";

import { Context } from "../stores/store";

import Sidebar from "./Sidebar";
import Header from "./Header";

const Main = ({
  isAuthenticated,
  searchLibrary,
  searchMyOverlays,
  searchMyImages,
  searchInbox,
  onSearchLibrary,
  onSearchMyOverlays,
  onSearchMyImages,
  onSearchInbox,
  onShowDialog,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [ContextState, ContextDispatch] = useContext(Context);

  // check if the url is login and if we are authenticated
  useEffect(() => {
    // translate the urls into open dialog
    if (location.pathname.indexOf("/resetpassword/") === 0) {
      onShowDialog("resetpassword");
      return;
    }

    if (location.pathname === "/feedback") {
      onShowDialog("sendfeedback");
      navigate("/home", { replace: true });
      return;
    }

    if (location.pathname === "/login" || location.pathname === "/signup") {
      if (
        ContextState.authenticationStatus === "" ||
        ContextState.authenticationStatus === "busy"
      ) {
        return;
      }
      if (ContextState.authenticationStatus === "ok") {
        navigate("/myoverlays/all", { replace: true });
      } else {
        if (location.pathname === "/login") {
          onShowDialog("login");
        } else {
          onShowDialog("signup");
        }

        navigate("/home", { replace: true });
        return;
      }
    }
    // we do not really have a root folder. We show home instead
    if (location.pathname === "/") {
      navigate("/home", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, ContextState.authenticationStatus]);

  // navigate to the global navigation if it is set
  useEffect(() => {
    // execute the action that is needed
    switch (ContextState.globalNavigation) {
      case "login":
        onShowDialog("login");
        break;
      case "signup":
        onShowDialog("signup");
        break;
      case "subscription":
        navigate("/subscription", { replace: true });
        break;
      default:
        return;
    }

    // reset the global navigation
    ContextDispatch({
      type: "SET_GLOBAL_NAVIGATION",
      payload: null,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ContextState.globalNavigation]);

  // when the search string changes check the URL we are on. If it is not correct
  // navigate the UI to the right place
  const onSearchLibraryUrlCheck = (search) => {
    onSearchLibrary(search);
    if (location.pathname.indexOf("/library") !== 0 && search) {
      navigate("/library");
    }
  };
  const onSearchMyOverlaysUrlCheck = (search) => {
    onSearchMyOverlays(search);
    if (location.pathname.indexOf("/myoverlays") !== 0 && search) {
      navigate("/myoverlays/all");
    }
  };
  const onSearchMyImagesUrlCheck = (search) => {
    onSearchMyImages(search);
    if (location.pathname.indexOf("/myimages") !== 0 && search) {
      navigate("/myimages/all");
    }
  };
  const onSearchInboxUrlCheck = (search) => {
    onSearchInbox(search);
    if (location.pathname.indexOf("/inbox") !== 0 && search) {
      navigate("/inbox");
    }
  };

  return (
    <div className="main">
      <Sidebar isAuthenticated={isAuthenticated} onShowDialog={onShowDialog} />
      <div className="right-side">
        <Header
          searchLibrary={searchLibrary}
          searchMyOverlays={searchMyOverlays}
          searchMyImages={searchMyImages}
          searchInbox={searchInbox}
          onShowDialog={onShowDialog}
          onSearchLibrary={onSearchLibraryUrlCheck}
          onSearchMyOverlays={onSearchMyOverlaysUrlCheck}
          onSearchMyImages={onSearchMyImagesUrlCheck}
          onSearchInbox={onSearchInboxUrlCheck}
        />
        <Outlet />
      </div>
    </div>
  );
};

export default Main;

import React, { useEffect, useState, useContext } from "react";
import { Context } from "../stores/store";
import { IconClose, IconHelp } from "../utilities/SvgIcon";
import Config from "../stores/Config";
import Util from "../utilities/Util";

const Banner = () => {
  const [ContextState] = useContext(Context);

  const [bannerId, setBannerId] = useState("");
  const [bannerVisible, setBannerVisible] = useState(false);

  // this will be called when the user logs in
  useEffect(() => {
    if (ContextState.userInfo) {
      setBannerId(ContextState.userInfo.bannerId);

      // show the banner with a delay of 5 seconds
      setTimeout(() => {
        setBannerVisible(true);
      }, 5000);
    }
  }, [ContextState.userInfo]);

  const onClose = (sendClose) => {
    // clear the banner ID to hide the banner
    setBannerVisible(false);

    // reset the banner after 1 second
    setTimeout(() => {
      setBannerId("");
    }, 1000);

    // reset the banner ID of the user
    fetch(Config.singularUrl + "/apiv2/campaigns/banner", {
      method: "DELETE",
      headers: Util.getFetchHeaders(ContextState),
    });

    if (sendClose) {
      sendBannerEvent("close");
    }
  };

  const sendBannerEvent = (event) => {
    // send the event to the server
    fetch("/api/library/answers", {
      method: "POST",
      headers: Util.getFetchHeaders(ContextState),
      body: JSON.stringify({
        questionId: bannerId,
        user: ContextState.userInfo.email,
        answer: event,
      }),
    });
  };

  // const onVote = (vote) => {
  //   // send the vote to the server
  //   sendBannerEvent(vote);

  //   // close the dialog
  //   onClose(false);
  // };

  // these are the various banner render functions
  // const renderBannerTest1 = () => {
  //   return (
  //     <div>
  //       <div className="title">This is my title</div>
  //       <div className="subtitle">This is the sub title</div>
  //     </div>
  //   );
  // };
  // const renderBannerTest2 = () => {
  //   return (
  //     <div className="line">
  //       <div className="title">How much do you like uno?</div>
  //       <div></div>
  //       <div></div>
  //       <div></div>
  //       <button className="button" onClick={() => onVote("Very")}>
  //         Very
  //       </button>
  //       <button className="button" onClick={() => onVote("So so")}>
  //         So so
  //       </button>
  //       <button className="button" onClick={() => onVote("Not at all")}>
  //         Not at all
  //       </button>
  //     </div>
  //   );
  // };

  // we render the banner based on the bannerId
  const renderBannerContent = () => {
    switch (bannerId) {
      // case "test1":
      //   return renderBannerTest1();
      // case "test2":
      //   return renderBannerTest2();
      default:
        return null;
    }
  };

  // if we have no banner content, we don't render anything
  const bannerContent = renderBannerContent();
  if (!bannerContent) {
    return null;
  }

  // render the banner
  return (
    <div className={"banner" + (bannerVisible ? " visible" : "")}>
      <IconHelp className="icon" fill="#fff" />
      <div className="content">{bannerContent}</div>
      <span className="close" onClick={() => onClose(true)}>
        <IconClose />
      </span>
    </div>
  );
};

export default Banner;

import Util from "./Util";
import Config from "../stores/Config";

const exportedObject = {
  fetchPortalConfig: (
    ContextState,
    ContextDispatch,
    subdomain,
    forceRefresh
  ) => {
    const controller = new AbortController();

    // if we have a subdomain, use that one, otherwise use the default
    let url = "";
    if (subdomain && Config.partnerPortalSubDomains[subdomain]) {
      url = Config.partnerPortalSubDomains[subdomain];
    } else {
      // the default us uno
      url = Config.partnerPortalSubDomains["uno"];
    }

    if (forceRefresh) {
      url += "?rnd=" + Math.random();
    }

    fetch(url, {
      signal: controller.signal,
      method: "GET",
    })
      .then((res) => res.json())
      .then((result) => {
        ContextDispatch({
          type: "SET_PORTAL_CONFIG",
          payload: result,
        });
      })
      .catch((error) => {
        if (!controller.signal.aborted) {
          // set the default portal config
          ContextDispatch({
            type: "SET_PORTAL_CONFIG",
            payload: Config.partnerPortalConfigDefault,
          });
        }
      });
    return controller;
  },

  fetchCustomAds: (ContextState, ContextDispatch, forceRefresh) => {
    const controller = new AbortController();
    let url = Config.unoCustomAdsUrl;
    if (forceRefresh) {
      url += "?rnd=" + Math.random();
    }

    fetch(url, {
      signal: controller.signal,
      method: "GET",
    })
      .then((res) => res.json())
      .then((result) => {
        ContextDispatch({
          type: "SET_UNO_ADS",
          payload: result,
        });
        ContextDispatch({
          type: "SET_UNO_ADS_LOADED",
          payload: true,
        });
      })
      .catch((error) => {
        if (!controller.signal.aborted) {
          // set the default portal config
          ContextDispatch({
            type: "SET_UNO_ADS",
            payload: null,
          });
          ContextDispatch({
            type: "SET_UNO_ADS_LOADED",
            payload: true,
          });
        }
      });
    return controller;
  },

  fetchLibraryItems: (ContextState, ContextDispatch, subdomain, showDraft) => {
    const controller = new AbortController();
    fetch("/api/items" + (showDraft ? "?showDraft" : ""), {
      signal: controller.signal,
      method: "GET",
      headers: Util.getFetchHeaders(ContextState),
    })
      .then((res) => res.json())
      .then((data) => {
        // convert the JSON strings into objects and make sure the data in the packages is correct
        let packages = Util.processItemArray(data);

        // make sure that the tags are ok
        let result = packages.map((item) => {
          item.tag_type = item.tag_type || "";
          item.tag_function = item.tag_function || "";
          item.tag_category = item.tag_category || "";
          item.tag_theme = item.tag_theme || "";
          item.tag_layout = item.tag_layout || "";
          item.tag_datasource = item.tag_datasource || "";
          return item;
        });

        // the uno_uno logic
        // if we don't have a sub domain we reject all items with uno_ tags,
        // but we want to keep the ones with an uno_uno tag
        // this will allow us to have partner page elements that are also shown
        // in the normal uno page
        let subdomainKeeper = "uno_uno";
        if (subdomain) {
          // if we have a subdomain, keep all items with that uno_<subdomain> tag, but not the other uno_* tags
          subdomainKeeper = "uno_" + subdomain;
        }

        // filter the items based on the tags
        result = result.filter((item) => {
          // keep all without tags
          if (!item.tags) {
            return true;
          }

          // loop through all tags and check if the item has the tag we are looking for, but no other tag that starts with uno_
          let keep = true;
          let tags = item.tags.map((tag) => tag.toLowerCase());
          for (let tag of tags) {
            if (subdomainKeeper === "") {
              // reject these
              if (tag.startsWith("uno_")) {
                keep = false;
                break;
              }
            } else {
              // accept these
              if (tag === subdomainKeeper) {
                keep = true;
                break;
                // reject these
              } else if (tag.startsWith("uno_")) {
                keep = false;
              }
            }
          }
          return keep;
        });

        // sort the items by "new" and then by updated_at
        let resultSort = result.slice();
        resultSort.sort((a, b) => {
          // get how many days are left for the new state
          let leftA = Util.getLibraryNewReleaseDaysLeft(a);
          let leftB = Util.getLibraryNewReleaseDaysLeft(b);

          // if both have days left then sort by the days left
          if (leftA && leftB) {
            if (leftA > leftB) {
              return -1;
            }
            if (leftA < leftB) {
              return 1;
            }
          } else if (leftA) {
            return -1;
          } else if (leftB) {
            return 1;
          }

          if (a.updated_at < b.updated_at) {
            return 1;
          }
          if (a.updated_at > b.updated_at) {
            return -1;
          }
          return a.name.localeCompare(b.name);
        });

        // now get the items that are new, but get at least 6 items
        let newItems = [];
        for (let item of resultSort) {
          if (
            Util.getLibraryNewReleaseDaysLeft(item) > 0 ||
            newItems.length < 6
          ) {
            newItems.push({
              id: item.id,
            });
          } else {
            break;
          }
        }

        ContextDispatch({
          type: "SET_LIBRARY_CONTENT",
          payload: result,
        });
        ContextDispatch({
          type: "SET_LIBRARY_CONTENT_LOADED",
          payload: true,
        });

        ContextDispatch({
          type: "SET_LIBRARY_NEW",
          payload: newItems,
        });
        ContextDispatch({
          type: "SET_LIBRARY_NEW_LOADED",
          payload: true,
        });
      })
      .catch((error) => {
        if (!controller.signal.aborted) {
          Util.addNotification("Error while fetching library content", "error");
          ContextDispatch({
            type: "SET_LIBRARY_CONTENT_LOADED",
            payload: true,
          });
          ContextDispatch({
            type: "SET_LIBRARY_NEW_LOADED",
            payload: true,
          });
        }
      });
    return controller;
  },

  fetchTopDownloaded: (ContextState, ContextDispatch) => {
    const controller = new AbortController();
    fetch("/api/items/topdownloaded", {
      signal: controller.signal,
      method: "GET",
    })
      .then((res) => res.json())
      .then((result) => {
        if (Array.isArray(result)) {
          ContextDispatch({
            type: "SET_LIBRARY_POPULAR",
            payload: result,
          });
        }
        ContextDispatch({
          type: "SET_LIBRARY_POPULAR_LOADED",
          payload: true,
        });
      })
      .catch((error) => {
        if (!controller.signal.aborted) {
          ContextDispatch({
            type: "SET_LIBRARY_POPULAR_LOADED",
            payload: true,
          });
        }
      });
    return controller;
  },

  fetchTrending: (ContextState, ContextDispatch) => {
    const controller = new AbortController();
    fetch("/api/items/trending", {
      signal: controller.signal,
      method: "GET",
    })
      .then((res) => res.json())
      .then((result) => {
        if (Array.isArray(result)) {
          ContextDispatch({
            type: "SET_LIBRARY_TRENDING",
            payload: result,
          });
        }
        ContextDispatch({
          type: "SET_LIBRARY_TRENDING_LOADED",
          payload: true,
        });
      })
      .catch((error) => {
        if (!controller.signal.aborted) {
          ContextDispatch({
            type: "SET_LIBRARY_TRENDING_LOADED",
            payload: true,
          });
        }
      });
    return controller;
  },

  fetchDashboardRootFolders: (ContextState, ContextDispatch) => {
    // if we are not authenticated then we don't need to load the folders
    if (ContextState.authenticationStatus !== "ok") {
      ContextDispatch({
        type: "SET_DASHBOARD_ROOT_FOLDER_OVERLAYS",
        payload: null,
      });
      ContextDispatch({
        type: "SET_DASHBOARD_ROOT_FOLDER_IMAGES",
        payload: null,
      });
      return;
    }

    const createDashboardRootFolder = (folder, type, databoardTypeFilter) => {
      const setRootFolder = (t, id) => {
        ContextDispatch({
          type: t,
          payload: id,
        });
      };

      fetch(Config.singularUrl + "/apiv2/dashboard/folder/root", {
        method: "POST",
        headers: Util.getFetchHeaders(ContextState),
        body: JSON.stringify({
          name: folder,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data && data.error) {
            throw data.error;
          }

          // get all items of a certain type from root and move them to the new folder
          fetch(
            Config.singularUrl +
              "/apiv2/dashboard/folder/root/items?type=" +
              databoardTypeFilter,
            {
              method: "GET",
              headers: Util.getFetchHeaders(ContextState),
            }
          )
            .then((res) => res.json())
            .then((result) => {
              if (result && result.error) {
                throw result.error;
              }

              // move all items to the new folder
              if (result.data.length > 0) {
                // build an array of the ids
                let ids = [];
                for (let item of result.data) {
                  ids.push(item.id);
                }

                // move the items
                fetch(
                  Config.singularUrl +
                    "/apiv2/dashboard/folder/" +
                    data.id +
                    " /items",
                  {
                    method: "POST",
                    headers: Util.getFetchHeaders(ContextState),
                    body: JSON.stringify({
                      items: ids,
                    }),
                  }
                )
                  .then((res) => res.json())
                  .then((result) => {
                    if (result && result.error) {
                      throw result.error;
                    }
                    // all items were moved
                    setRootFolder(type, data.id);
                  });
              } else {
                // we didn't have any items to move
                setRootFolder(type, data.id);
              }
            })
            .catch((error) => {
              // we couldn't get the items
              setRootFolder(type, data.id);
            });
        });
    };

    // load the users dashboard root folders
    const controller = new AbortController();
    fetch(
      Config.singularUrl +
        "/apiv2/dashboard/folder/root/items?type=folder&sortby=name&sortdirection=asc",
      {
        signal: controller.signal,
        method: "GET",
        headers: Util.getFetchHeaders(ContextState),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data && data.error) {
          throw data.error;
        }

        // find the folder called "overlays"
        const folderOverlays = data.data.find((i) => i.name === "uno overlays");
        if (folderOverlays) {
          ContextDispatch({
            type: "SET_DASHBOARD_ROOT_FOLDER_OVERLAYS",
            payload: folderOverlays.id,
          });
        } else {
          createDashboardRootFolder(
            "uno overlays",
            "SET_DASHBOARD_ROOT_FOLDER_OVERLAYS",
            "controlapp"
          );
        }
        // find the folder called "images"
        const folderImages = data.data.find((i) => i.name === "uno images");
        if (folderImages) {
          ContextDispatch({
            type: "SET_DASHBOARD_ROOT_FOLDER_IMAGES",
            payload: folderImages.id,
          });
        } else {
          createDashboardRootFolder(
            "uno images",
            "SET_DASHBOARD_ROOT_FOLDER_IMAGES",
            "image"
          );
        }
      })
      .catch((error) => {});
    return controller;
  },

  fetchDashboardFoldersOverlays: (ContextState, ContextDispatch) => {
    if (!ContextState.dashboardRootFolderOverlays) {
      ContextDispatch({
        type: "SET_DASHBOARD_FOLDER_OVERLAYS",
        payload: null,
      });
      return;
    }

    const controller = new AbortController();
    fetch(
      Config.singularUrl +
        "/apiv2/dashboard/folder/" +
        ContextState.dashboardRootFolderOverlays +
        "/items?type=folder&sortby=name&sortdirection=asc",
      {
        signal: controller.signal,
        method: "GET",
        headers: Util.getFetchHeaders(ContextState),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data && data.error) {
          throw data.error;
        }
        ContextDispatch({
          type: "SET_DASHBOARD_FOLDER_OVERLAYS",
          payload: data.data,
        });
      })
      .catch((error) => {});
    return controller;
  },

  fetchDashboardFoldersImages: (ContextState, ContextDispatch) => {
    if (!ContextState.dashboardRootFolderImages) {
      ContextDispatch({
        type: "SET_DASHBOARD_FOLDER_IMAGES",
        payload: null,
      });
      return;
    }

    const controller = new AbortController();
    fetch(
      Config.singularUrl +
        "/apiv2/dashboard/folder/" +
        ContextState.dashboardRootFolderImages +
        "/items?type=folder&sortby=name&sortdirection=asc",
      {
        signal: controller.signal,
        method: "GET",
        headers: Util.getFetchHeaders(ContextState),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data && data.error) {
          throw data.error;
        }
        ContextDispatch({
          type: "SET_DASHBOARD_FOLDER_IMAGES",
          payload: data.data,
        });
      })
      .catch((error) => {});
    return controller;
  },

  fetchDashboardInbox: (ContextState, ContextDispatch) => {
    const controller = new AbortController();
    fetch(
      Config.singularUrl +
        "/apiv2/dashboard/folder/inbox/items?type=controlapp&sortby=name&sortdirection=asc",
      {
        signal: controller.signal,
        method: "GET",
        headers: Util.getFetchHeaders(ContextState),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data && data.error) {
          throw data.error;
        }
        ContextDispatch({
          type: "SET_DASHBOARD_INBOX",
          payload: data.data,
        });
        ContextDispatch({
          type: "SET_DASHBOARD_INBOX_LOADED",
          payload: true,
        });
      })
      .catch((error) => {});
    return controller;
  },
};

export default exportedObject;

import React, { useEffect, useState, useContext } from "react";
import { Context } from "../stores/store";
import { IconSpinner } from "../utilities/SvgIcon";

import Util from "../utilities/Util";

const ADVERTISEMENT_UPDATE_INTERVAL = 60000;
const ADVERTISEMENT_UPDATE_INTERVAL_DELTA = 30000;
const MAX_NUMBER_OF_GOOGLE_ADS = 3;

const GOOGLE_AD_SLOT_ID = ["2889999909", "6170181000", "5922768991"].sort(
  () => Math.random() - 0.5
);

// calculate the number of google ads to be displayed
const calculateNumberOfGoogleAds = () => {
  return Math.floor(Math.random() * MAX_NUMBER_OF_GOOGLE_ADS) + 1;
};

// how often should we reload the ads?
const calculateAdRefreshInterval = () => {
  return (
    ADVERTISEMENT_UPDATE_INTERVAL +
    Math.round(Math.random() * ADVERTISEMENT_UPDATE_INTERVAL_DELTA)
  );
};

const Ads = ({ layout, onShowDialog }) => {
  const [ContextState, ContextDispatch] = useContext(Context);

  const [unoAdsRedraw, setUnoAdsRedraw] = useState(0);
  const [unoAds, setUnoAds] = useState([]);
  const [googleAdsLibrayLoaded, setGoogleAdsLibrayLoaded] = useState(false);
  const [numberOfGoogleAds, setNumberOfGoogleAds] = useState(
    calculateNumberOfGoogleAds()
  );

  // load the google ads library
  useEffect(() => {
    if (window.adsbygoogle) {
      return;
    }
    const script = document.createElement("script");
    script.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9826658694170953";
    script.async = true;
    script.onload = function () {
      setGoogleAdsLibrayLoaded(true);
    };

    // handle the error
    script.onerror = function () {
      console.log("Error loading AdSense library");
      setGoogleAdsLibrayLoaded(true);
    };
    document.head.appendChild(script);
  }, []);

  // initialize AdSense and set the interval to redraw the ads
  useEffect(() => {
    // display the ads in a random order
    const intervalHandler = setInterval(() => {
      setUnoAdsRedraw((prev) => prev + 1);
      setNumberOfGoogleAds(calculateNumberOfGoogleAds());
    }, calculateAdRefreshInterval());
    return () => clearInterval(intervalHandler);
  }, []);

  // randomize the ads after they have been loaded
  useEffect(() => {
    if (
      ContextState.unoAdsLoaded === false ||
      !ContextState.unoAds ||
      !ContextState.unoAds.ads
    ) {
      setUnoAds([]);
      return;
    }

    const ads = [...ContextState.unoAds.ads];
    ads.sort(() => Math.random() - 0.5);
    setUnoAds(ads);
  }, [ContextState.unoAds, unoAdsRedraw, ContextState.unoAdsLoaded]);

  // reset the ads after the redraw
  useEffect(() => {
    // if I don't have uno ads yet or the google ads library is not loaded
    if (unoAds.length === 0 || googleAdsLibrayLoaded === false) {
      return;
    }

    // now initialize all the google ads on the page, but wait a second between each of
    // them to get different ads
    for (let i = 0; i < numberOfGoogleAds; i++) {
      setTimeout(() => {
        try {
          if (window) {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
          }
        } catch (e) {
          console.log("Error initializing AdSense", i, e);
        }
      }, 1000 * i);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    googleAdsLibrayLoaded,
    unoAdsRedraw,
    unoAds.length,
    ContextState.unoAdsLoaded,
  ]);

  // render an ad slot
  const renderAdSlot = (ad, index) => {
    const renderUnoAd = () => {
      return (
        <div
          key={index}
          className={"uno-ad" + (ad.link ? " link" : "")}
          onClick={() => {
            if (ad.link) {
              Util.openUrl(ad.link);
            }
          }}
        >
          <img
            className="image"
            src={ad.imageUrl}
            alt={ad.imageAlt || "Advertisement " + index}
          />
          {ad.title && <div className="title">{ad.title}</div>}
          {ad.message && <div className="message">{ad.message}</div>}
        </div>
      );
    };

    const renderGoogleAd = () => {
      // this defines the number of google ads to be displayed
      if (index >= numberOfGoogleAds) {
        return null;
      }

      return (
        <ins
          key={"adsbygoogle-" + unoAdsRedraw + "-" + index}
          className="adsbygoogle"
          style={{
            paddingRight: layout === "horizontal" ? "10px" : "0px",
          }}
          data-ad-client="ca-pub-9826658694170953"
          data-ad-slot={GOOGLE_AD_SLOT_ID[index]}
          data-ad-format="fixed"
          data-full-width-responsive="false"
        ></ins>
      );
    };

    /*
    // this is for testing
    const renderPlaceholder = () => {
      // this defines the number of google ads to be displayed
      if (index >= numberOfGoogleAds) {
        return null;
      }

      return (
        <div
          key={"adsbygoogle-" + unoAdsRedraw + "-" + index}
          className="google-ads-placeholder"
          style={{ background: "red" }}
        >
          {index}
        </div>
      );
    };
    */

    return (
      <div key={index} className="good-stuff-slot">
        {renderGoogleAd()}
        {renderUnoAd()}
      </div>
    );
  };

  // render all the ads, but only if they are loaded
  const renderAds = () => {
    if (ContextState.unoAdsLoaded === false) {
      return (
        <div className="loading">
          <IconSpinner />
        </div>
      );
    }
    return (
      <>
        {unoAds.map((ad, index) => {
          return renderAdSlot(ad, index);
        })}
      </>
    );
  };

  const renderRemoveAds = () => {
    // this will keep the space. If we are not authenticated
    // nothing will be rendered
    if (ContextState.authenticationStatus === "busy") {
      return <div className="remove-good-stuff"></div>;
    }

    return (
      <div className="remove-good-stuff">
        Want to enjoy an ad-free experience?
        <div
          className="link"
          onClick={() => {
            if (ContextState.authenticationStatus !== "ok") {
              onShowDialog("login");
            } else {
              ContextDispatch({
                type: "SET_GLOBAL_NAVIGATION",
                payload: "subscription",
              });
            }
          }}
        >
          Remove Ads
        </div>
      </div>
    );
  };

  // render the ads
  return (
    <div className={"good-stuff " + layout}>
      <div className="good-stuff-title">Sponsored</div>
      <div className="uno-good-stuff">{renderAds()}</div>
      {renderRemoveAds()}
    </div>
  );
};

export default Ads;

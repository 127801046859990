import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import { IconAngleLeft, IconAngleRight } from "../utilities/SvgIcon";
import HomeLibraryItem from "./HomeLibraryItem";
import Util from "../utilities/Util";

const HomeLibrarySet = ({
  title,
  items,
  numberOfItems,
  libraryContent,
  onFilterChanged,
}) => {
  const [itemsToRender, setItemsToRender] = useState([]);
  const navigate = useNavigate();

  let responsive = {};
  for (let i = 1; i < 10; i++) {
    responsive["step-" + i] = {
      breakpoint: {
        max: 600 * i,
        min: 600 * (i - 1),
      },
      items: i,
      partialVisibilityGutter: 40,
    };
  }

  useEffect(() => {
    let result = [];
    // get the first numberOfItems elements from the library using the ID in items
    if (items) {
      items.forEach((item) => {
        if (!numberOfItems || result.length < numberOfItems) {
          const found = libraryContent.find(
            (libraryItem) => libraryItem.id === item.id
          );
          if (found) {
            result.push(found);
          }
        }
      });
    }
    setItemsToRender(result);
  }, [items, numberOfItems, libraryContent]);

  const CustomArrow = ({ onClick, ...rest }) => {
    const { direction } = rest;
    // onMove means if dragging or swiping in progress.
    return (
      <div
        className={"arrow-button " + (direction === "left" ? "left" : "right")}
        onClick={() => onClick()}
      >
        {direction === "left" && <IconAngleLeft />}
        {direction === "right" && <IconAngleRight />}
      </div>
    );
  };

  const onSelectItem = (item) => {
    let url = Util.getLibraryItemPath(item);
    if (url) {
      onFilterChanged("clear");
      navigate("/library/" + url);
    }
  };

  const onSelectFilter = (e, filter, value) => {
    onFilterChanged("set", filter, value);
    navigate("/library");
    e.stopPropagation();
  };

  // don't render anything without data
  if (!items || !items.length) {
    return null;
  }

  return (
    <div className="home-library-set">
      <h2 className="title">{title}</h2>
      <Carousel
        swipeable={true}
        draggable={false}
        responsive={responsive}
        centerMode={true}
        infinite={true}
        partialVisible={false}
        customRightArrow={<CustomArrow direction="right" />}
        customLeftArrow={<CustomArrow direction="left" />}
        autoPlay={false}
        containerClass="carousel"
      >
        {itemsToRender.map((item, index) => (
          <HomeLibraryItem
            key={index}
            size="small"
            item={item}
            onSelectItem={onSelectItem}
            onSelectFilter={onSelectFilter}
          />
        ))}
      </Carousel>
      <div className="shadow-left"></div>
      <div className="shadow-right"></div>
    </div>
  );
};

export default HomeLibrarySet;

import { useContext } from "react";

import { Context } from "../stores/store";
import Util from "../utilities/Util";

import { IconClose } from "../utilities/SvgIcon";

const SingularInfo = ({ onShowDialog, onClose }) => {
  const [, ContextDispatch] = useContext(Context);

  // handle logout and then forward to signup page
  const handleLogout = () => {
    Util.resetAllTokens(ContextDispatch);
    onClose();
    onShowDialog("signup");
  };

  return (
    <div className="content">
      <div className="sendfeedback-header">
        <div className="title">Important notice</div>
        <span className="close" onClick={() => onClose()}>
          <IconClose />
        </span>
      </div>
      <div className="message">
        You’re using a free Singular account to log into
        overlays.uno, as a result uno outputs will display a watermark.
        <br />
        <br />
        To avoid the watermark sign up with a different email address{" "}
        <span className="message-link" onClick={handleLogout}>
          here
        </span>{" "}
        or upgrade your Singular account to a paid plan.
      </div>
      <button className="button" value="Dismiss" onClick={onClose}>
        Dismiss
      </button>
    </div>
  );
};

export default SingularInfo;

import React, { useContext, useState, useEffect } from "react";
import { Context } from "../stores/store";

import { useNavigate, useLocation } from "react-router-dom";

import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";

import Util from "../utilities/Util";
import Config from "../stores/Config";

import {
  IconSpinner,
  IconNewOverlay,
  IconAll,
  IconTrash,
  IconEditText,
  IconMenu,
  IconAngleUp,
  IconAngleDown,
} from "../utilities/SvgIcon";

const SidebarFolders = ({
  header,
  dashboardType,
  parentFolder,
  folders,
  show,
  onShow,
  reloadFolders,
  onFolderClick,
}) => {
  const [ContextState, ContextDispatch] = useContext(Context);

  const [busyFolder, setBusyFolder] = useState(true);

  const [createFolder, setCreateFolder] = useState(false);
  const [renameFolder, setRenameFolder] = useState(false);
  const [inputFolderName, setInputFolderName] = useState(false);
  const [removeFolder, setRemoveFolder] = useState(false);

  const [renameFolderId, setRenameFolderId] = useState(null);
  const [removeFolderId, setRemoveFolderId] = useState(null);

  const [newFolderName, setNewFolderName] = useState("");
  const [currentFolderName, setCurrentFolderName] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  const resetFolderUI = () => {
    setCreateFolder(false);
    setRenameFolder(false);
    setBusyFolder(false);
    setRemoveFolder(false);
  };

  useEffect(() => {
    if (folders) {
      resetFolderUI();
    }
  }, [folders]);

  useEffect(() => {
    if (ContextState.dashboardCreateFolder === dashboardType) {
      // initiate the creation of a new folder
      onShow(true);
      setInputFolderName(true);
      setCreateFolder(true);
      setNewFolderName("New Folder");
    } else {
      // we are not creating a folder anymore
      setInputFolderName(false);
      setCreateFolder(false);
      setRenameFolder(false);
      setRenameFolderId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ContextState.dashboardCreateFolder]);

  const isSelectedFolder = (folder) => {
    return String(location.pathname).indexOf(folder) === 0;
  };

  // add a selected class to the menu based on the react router location
  const classForLocation = (c, l) => {
    if (isSelectedFolder(l)) {
      return c + " selected";
    } else {
      return c;
    }
  };

  const renderFolders = () => {
    const showAdd = !createFolder && !busyFolder && !renameFolder;
    const hasFolders = folders && folders.length !== 0;

    if (ContextState.menuSize === "large") {
      return (
        <>
          <span
            className="title"
            onClick={() => onFolderClick(parentFolder, header + "/folders")}
          >
            Root folder
          </span>

          {showAdd && (
            <div
              className="add"
              onClick={() => {
                ContextDispatch({
                  type: "SET_DASHBOARD_CREATE_FOLDER",
                  payload: dashboardType,
                });
              }}
            >
              <IconNewOverlay />
            </div>
          )}

          {hasFolders && showAdd && (
            <div className="show" onClick={() => onShow(!show)}>
              {show ? <IconAngleUp /> : <IconAngleDown />}
            </div>
          )}
          {busyFolder && (
            <div className="busy nomouse">
              <IconSpinner className="icon small" />
            </div>
          )}
        </>
      );
    } else {
      return (
        <>
          <span
            className="title"
            onClick={() => onFolderClick(parentFolder, header + "/folders")}
          >
            <IconAll className="icon" />
          </span>
        </>
      );
    }
  };

  const renderFolder = (icon, folder) => {
    if (ContextState.menuSize === "large") {
      return (
        <>
          {icon}
          <span className="title">{folder.name}</span>
          <div className="dropdownmenu" onClick={(e) => e.stopPropagation()}>
            <Menu
              menuButton={
                <MenuButton
                  onClick={(e) => e.stopPropagation()}
                  onDoubleClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <IconMenu className="icon" />
                </MenuButton>
              }
              transition
              align="end"
              menuClassName="menu-background"
            >
              <MenuItem
                onClick={() => {
                  setRenameFolderId(folder.id);
                  setNewFolderName(folder.name);
                  setCurrentFolderName(folder.name);
                  setInputFolderName(true);
                  setRenameFolder(true);
                }}
                className="menu-item"
              >
                <IconEditText />
                Rename
              </MenuItem>
              <MenuItem
                onClick={() => onRemoveFolder(folder.id)}
                className="menu-item last"
              >
                <IconTrash />
                Remove
              </MenuItem>
            </Menu>
          </div>
        </>
      );
    } else {
      return (
        <>
          <span className="title">{folder.name.substring(0, 3)}</span>
        </>
      );
    }
  };

  const onInputFolderName = () => {
    // if we don't have a folder name
    if (newFolderName === "") {
      ContextDispatch({
        type: "SET_DASHBOARD_CREATE_FOLDER",
        payload: null,
      });
      return;
    }

    // if we rename the folder but the name didn't change
    if (renameFolder && newFolderName === currentFolderName) {
      resetFolderUI();
      return;
    }

    // create the folder, reload the folders and then navigate to it
    if (createFolder) {
      setInputFolderName(false);
      setBusyFolder(true);

      fetch(Config.singularUrl + "/apiv2/dashboard/folder/" + parentFolder, {
        method: "POST",
        headers: Util.getFetchHeaders(ContextState),
        body: JSON.stringify({
          name: newFolderName,
        }),
      }).then((response) => {
        ContextDispatch({
          type: "SET_DASHBOARD_CREATE_FOLDER",
          payload: null,
        });
        if (response.ok) {
          reloadFolders();
          return response.json();
        }
      });
    }

    if (renameFolder) {
      setInputFolderName(false);
      setBusyFolder(true);

      fetch(Config.singularUrl + "/apiv2/dashboard/folder/" + renameFolderId, {
        method: "PATCH",
        headers: Util.getFetchHeaders(ContextState),
        body: JSON.stringify({
          name: newFolderName,
        }),
      }).then((response) => {
        if (response.ok) {
          reloadFolders();
          return response.json();
        } else {
          setBusyFolder(false);
          setRenameFolder(false);
        }
      });
    }
  };

  const onRemoveFolder = (id) => {
    setBusyFolder(true);
    setRemoveFolder(true);
    setRemoveFolderId(id);

    // convert the dashboard type to the type that the API expects
    let type = "invalid";
    if (dashboardType === "images") {
      type = "image";
    } else if (dashboardType === "controlapps") {
      type = "controlapp";
    }

    // fetch the content of the folder to make sure that it is empty
    fetch(
      Config.singularUrl +
        "/apiv2/dashboard/folder/" +
        id +
        "/items?type=" +
        type,
      {
        method: "GET",
        headers: Util.getFetchHeaders(ContextState),
      }
    )
      .then((res) => res.json())
      .then((result) => {
        Util.handleFetchError(result, ContextDispatch, navigate);

        // we can only delete empty folders
        if (result.data && result.data.length > 0) {
          setBusyFolder(false);
          setRemoveFolder(false);
          Util.addNotification("Only empty folders can be deleted", "error");
          return;
        }

        // now delete the folder
        fetch(Config.singularUrl + "/apiv2/dashboard/folder/" + id, {
          method: "DELETE",
          headers: Util.getFetchHeaders(ContextState),
        }).then((response) => {
          if (response.ok) {
            reloadFolders();
            // if we delete the current folder, navigate to the root
            if (isSelectedFolder(header + "/" + id)) {
              onFolderClick(parentFolder, header + "/folders");
            }
            return response.json();
          } else {
            setBusyFolder(false);
            setRemoveFolder(false);
          }
        });
      })
      .catch((error) => {
        setBusyFolder(false);
        setRemoveFolder(false);
      });
  };

  const renderInputFolderName = () => {
    if (inputFolderName) {
      return (
        <div className="entry sub" key="rename">
          <input
            type="text"
            className="input"
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
            onFocus={(e) => e.target.select()}
            onBlur={(e) => onInputFolderName()}
            onKeyDown={(e) => {
              if (e.key === "Escape") {
                ContextDispatch({
                  type: "SET_DASHBOARD_CREATE_FOLDER",
                  payload: null,
                });
                resetFolderUI();
                return;
              }
              if (e.key === "Enter") {
                onInputFolderName();
              }
            }}
            autoFocus
          />
        </div>
      );
    }
  };

  let foldersToRender = [];
  if (folders) {
    foldersToRender = folders;
  }

  return (
    <>
      <div
        className={
          classForLocation("entry sub folders", header + "/folders") +
          " highlight"
        }
      >
        {renderFolders()}
      </div>

      {/* render the input field at the when we create a folder */}
      {createFolder && renderInputFolderName()}

      {/* render all the folders */}
      {show &&
        foldersToRender.map((folder, index) => {
          // we are renaming
          if (renameFolder && folder.id === renameFolderId) {
            if (busyFolder) {
              // show the busy spinner, because we are renaming the folder
              return (
                <div key="busy" className="entry sub nomouse">
                  <IconSpinner className="icon small" />
                </div>
              );
            } else {
              // we will show the rename field instead
              return renderInputFolderName();
            }
          }

          // we are deleting this folder
          if (removeFolder && removeFolderId === folder.id) {
            return (
              <div key="busy" className="entry sub nomouse">
                <IconSpinner className="icon small" />
              </div>
            );
          }

          return (
            <div
              key={index}
              className={
                classForLocation("entry sub", header + "/" + folder.id) +
                " highlight"
              }
              onClick={() => onFolderClick(folder.id, header + "/" + folder.id)}
            >
              {renderFolder(<IconAll className="icon" />, folder)}
            </div>
          );
        })}
    </>
  );
};

export default SidebarFolders;

import React, { useState, useEffect, useContext } from "react";
import { Context } from "../stores/store";
import { useNavigate, useParams } from "react-router-dom";

import { DndProvider, useDrop } from "react-dnd";
import { HTML5Backend, NativeTypes } from "react-dnd-html5-backend";

import Config from "../stores/Config";
import Util from "../utilities/Util";

import { IconSpinner, IconNewImage } from "../utilities/SvgIcon";

import DashboardImagesList from "./DashboardImagesList";
import DashboardHeader from "./DashboardHeader";

const DashboardMyImages = ({ search, onShowDialog }) => {
  const { folder } = useParams();

  const [ContextState, ContextDispatch] = useContext(Context);

  const navigate = useNavigate();

  const [apps, setApps] = useState([]);
  const [fetchAppBusy, setFetchAppBusy] = useState(true);

  const [itemIdBusy, setItemIdBusy] = useState([]);

  // we use a local start for the folder so the children of this UI component display
  // the folder correctly. The data depending on the folder (apps) are updated in the useEffect
  // when the folder changes and that is too late. I might see a flash of the wrong folder name
  // when it changes.
  const [localFolder, setLocalFolder] = useState("");

  // filter the apps
  let filtered = apps;
  const cleanSearch = search.trim().toUpperCase();
  if (cleanSearch) {
    filtered = apps.filter((item) => {
      return item.name.toUpperCase().indexOf(cleanSearch) !== -1;
    });
  }

  const convertItemApiResultToArray = (item) => {
    // return a clean and simplified version of the dashboard items for the UI
    return {
      name: item.name,
      id: item.id,
      thumbnail: Util.fixImageUrl(item.thumbnail),
      changed: item.updatedAt,
      url: item.url,
      favorite: item.favorite,
      folderId: item.folderId,
    };
  };

  useEffect(() => {
    // fetch data from the singular backend
    if (ContextState.authenticationStatus !== "ok") {
      return;
    }

    // we need the root folder to fetch the images
    if (!ContextState.dashboardRootFolderImages) {
      return;
    }

    // remove the old result
    setFetchAppBusy(true);
    setApps([]);
    setLocalFolder(folder);

    // construct the URL for the fetch
    let url, searchFolder;
    switch (folder) {
      case "all":
        // do a search for all items
        url = Config.singularUrl + "/apiv2/dashboard/search";
        searchFolder = ContextState.dashboardRootFolderImages;
        break;
      case "favorites":
        url = Config.singularUrl + "/apiv2/dashboard/folder/favorites/items";
        searchFolder = ContextState.dashboardRootFolderImages;
        break;
      case "folders":
        url =
          Config.singularUrl +
          "/apiv2/dashboard/folder/" +
          ContextState.dashboardRootFolderImages +
          "/items";
        break;
      default:
        url =
          Config.singularUrl + "/apiv2/dashboard/folder/" + folder + "/items";
        break;
    }

    // add the type query
    url += "?type=image";

    // limit the search to the image folder
    if (searchFolder) {
      url += "&folder=" + searchFolder;
    }

    // add the search query
    if (search.trim().length) {
      url += "&q=" + search.trim();
    }

    // add the sort query
    switch (ContextState.dashboardSort) {
      case "alphaZA":
        url += "&sortby=name&sortdirection=desc";
        break;
      case "changed19":
        url += "&sortby=updatedAt&sortdirection=asc";
        break;
      case "changed91":
        url += "&sortby=updatedAt&sortdirection=desc";
        break;
      case "alphaAZ":
      default:
        url += "&sortby=name&sortdirection=asc";
        break;
    }

    // add the max items limit
    url += "&maxitemlimit=500";

    // fetch the items
    const controller = new AbortController();
    fetch(url, {
      signal: controller.signal,
      method: "GET",
      headers: Util.getFetchHeaders(ContextState),
    })
      .then((res) => res.json())
      .then((result) => {
        Util.handleFetchError(result, ContextDispatch, navigate);

        // get all the app instances and cleanup the data
        let data = result.data.map((item) => {
          return convertItemApiResultToArray(item);
        });

        // set the data
        setApps(data);

        // cleanup the selection
        let newSelection = [];
        ContextState.dashboardSelection.forEach((id) => {
          if (data.find((i) => i.id === id)) {
            newSelection.push(id);
          }
        });
        if (newSelection.length !== ContextState.dashboardSelection.length) {
          ContextDispatch({
            type: "SET_DASHBOARD_SELECTION",
            payload: newSelection,
          });
        }

        setFetchAppBusy(false);
      })
      .catch((error) => {
        if (!controller.signal.aborted) {
          setFetchAppBusy(false);
          ContextDispatch({
            type: "SET_DASHBOARD_SELECTION",
            payload: [],
          });
        }
      });
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    search,
    folder,
    ContextState.dashboardSort,
    ContextState.authenticationStatus,
    ContextState.dashboardRootFolderImages,
    ContextState.dashboardImagesForceReload,
  ]);

  // when we rename an item we do not want to fetch all the data again.
  // we can simple replace the item we have in the app array
  const onUpdateItem = (item) => {
    let clonedApps = apps.map((a) => {
      if (a.id === item.id) {
        return convertItemApiResultToArray(item);
      } else {
        return a;
      }
    });
    setApps(clonedApps);
  };

  const onSetItemIdBusy = (id, busy) => {
    // we work with arrays here
    if (!Array.isArray(id)) {
      id = [id];
    }

    // add them to the list
    if (busy) {
      // make sure we don't add the same id twice
      const filtered = id.filter((item) => !itemIdBusy.includes(item));

      // add the filtered array to the existing array
      if (filtered.length > 0) {
        setItemIdBusy((itemIdBusy) => [...itemIdBusy, ...filtered]);
      }
    } else {
      setItemIdBusy(itemIdBusy.filter((item) => !itemIdBusy.includes(item)));
    }
  };

  const renderHeader = () => {
    const renderTitle = () => {
      switch (localFolder) {
        case "all":
          return "My images";
        case "favorites":
          return "My images / Favorites";
        case "trash":
          return "My images / Trash";
        case "folders":
          return "My images / Root folder";
        default: {
          return (
            "My images / " +
            Util.getFolderName(ContextState.dashboardFoldersImages, localFolder)
          );
        }
      }
    };

    return (
      <div className="list-header">
        <div className="title">{renderTitle()}</div>
        <DashboardHeader
          folder={localFolder}
          folders={ContextState.dashboardFoldersImages}
          rootFolderId={ContextState.dashboardRootFolderImages}
          busy={fetchAppBusy}
          items={filtered}
          dashboardType={"images"}
          onLoadItems={() => {
            ContextDispatch({
              type: "DASHBOARD_IMAGES_FORCE_RELOAD",
            });
          }}
          onSetItemIdBusy={onSetItemIdBusy}
        />
      </div>
    );
  };

  const renderContent = () => {
    if (fetchAppBusy) {
      return (
        <div className="fetch-busy">
          <IconSpinner />
        </div>
      );
    } else {
      return (
        <DashboardImagesList
          apps={apps}
          filtered={filtered}
          view={ContextState.dashboardView}
          folder={localFolder}
          itemIdBusy={itemIdBusy}
          onUpdateItem={onUpdateItem}
          onLoadItems={() => {
            ContextDispatch({
              type: "DASHBOARD_IMAGES_FORCE_RELOAD",
            });
          }}
          onSetItemIdBusy={onSetItemIdBusy}
          onShowDialog={onShowDialog}
        />
      );
    }
  };

  const DropZone = () => {
    const [{ isDragging }, dropRef] = useDrop({
      accept: NativeTypes.FILE,
      collect: (monitor) => ({
        isDragging: !!monitor.isOver(),
      }),
      drop: (item) => {
        if (item && item.files && item.files.length) {
          ContextDispatch({
            type: "SET_IMAGE_DROPPED",
            payload: item.files,
          });
          onShowDialog("uploadimage");
        }
      },
    });

    return (
      <div ref={dropRef} className="drop-zone">
        <div className="content">{renderContent()}</div>
        <div className={"drop-zone-overlay " + (isDragging ? " visible" : "")}>
          <div className="background">
            <IconNewImage className="icon" />
            <div className="title">Drop to upload images</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="dashboard-myimages">
      {renderHeader()}
      <DndProvider backend={HTML5Backend}>
        <DropZone />
      </DndProvider>
    </div>
  );
};

export default DashboardMyImages;

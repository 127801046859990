import React, { useContext } from "react";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import { Context } from "../stores/store";
import { useNavigate } from "react-router-dom";
import Config from "../stores/Config";
import Util from "../utilities/Util";
import FolderMenu from "./FolderMenu";

import {
  IconGrid,
  IconList,
  IconSort,
  IconTrash,
  IconCheckOn,
  IconCheckOff,
  IconCheckMixed,
  IconRestore,
  IconCopy,
} from "../utilities/SvgIcon";

const DashboardHeader = ({
  folder,
  folders,
  rootFolderId,
  busy,
  items,
  dashboardType,
  onSetItemIdBusy,
  onLoadItems,
}) => {
  const sortOptions = [
    {
      id: "alphaAZ",
      name: "A to Z",
    },
    {
      id: "changed91",
      name: "Recent",
    },
  ];

  const navigate = useNavigate();

  const [ContextState, ContextDispatch] = useContext(Context);

  const numberOfSelectedItems = ContextState.dashboardSelection.length;

  const renderCheckbox = () => {
    if (numberOfSelectedItems === 0) {
      return <IconCheckOff />;
    } else if (numberOfSelectedItems === items.length) {
      return <IconCheckOn />;
    } else {
      return <IconCheckMixed />;
    }
  };

  const refreshDashboard = () => {
    onSetItemIdBusy(ContextState.dashboardSelection, false);
    ContextDispatch({
      type: "SET_DASHBOARD_SELECTION",
      payload: [],
    });
    onLoadItems();
  };

  // execute an action on all selected items
  const onBulkAction = (action, parameter) => {
    onSetItemIdBusy(ContextState.dashboardSelection, true);

    // count the number of requests we need to make
    let numberOfRequests = ContextState.dashboardSelection.length;

    // delete each item
    ContextState.dashboardSelection.forEach((id, index) => {
      // delay each delete by 250ms to avoid flooding the server
      setTimeout(() => {
        action(
          id,
          () => {
            numberOfRequests--;
            if (numberOfRequests === 0) {
              refreshDashboard();
            }
          },
          parameter
        );
      }, 250 * index);
    });
  };

  const executeSetFolder = (id, done, folder) => {
    fetch(Config.singularUrl + "/apiv2/" + dashboardType + "/" + id, {
      method: "PATCH",
      headers: Util.getFetchHeaders(ContextState),
      body: JSON.stringify({ folder: folder }),
    })
      .then((res) => res.json())
      .then((result) => {
        Util.handleFetchError(result, ContextDispatch, navigate);
        done();
      })
      .catch((error) => {
        done();
      });
  };

  const executeDelete = (id, done, forever) => {
    fetch(Config.singularUrl + "/apiv2/" + dashboardType + "/" + id, {
      method: "DELETE",
      headers: Util.getFetchHeaders(ContextState),
      body: JSON.stringify({
        permanent: forever ? true : false,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        Util.handleFetchError(result, ContextDispatch, navigate);
        done();
      })
      .catch((error) => {
        done();
      });
  };

  const executeRestore = (id, done) => {
    fetch(
      Config.singularUrl + "/apiv2/" + dashboardType + "/" + id + "/restore",
      {
        method: "PUT",
        headers: Util.getFetchHeaders(ContextState),
      }
    )
      .then((res) => res.json())
      .then((result) => {
        Util.handleFetchError(result, ContextDispatch, navigate);
        done();
      })
      .catch((error) => {
        done();
      });
  };

  const executeCopyToMyOverlays = (id, done) => {
    fetch(
      Config.singularUrl + "/apiv2/" + dashboardType + "/" + id + "/duplicate",
      {
        method: "POST",
        headers: Util.getFetchHeaders(ContextState),
        body: JSON.stringify({
          folder: ContextState.dashboardRootFolderOverlays,
        }),
      }
    )
      .then((res) => res.json())
      .then((result) => {
        Util.handleFetchError(result, ContextDispatch, navigate);
        done();
      })
      .catch((error) => {
        done();
      });
  };

  const renderActions = () => {
    if (numberOfSelectedItems > 0) {
      if (folder === "trash") {
        return (
          <>
            <div
              className="action"
              onClick={() => onBulkAction(executeRestore)}
            >
              <IconRestore />
            </div>
            <div
              className="action"
              onClick={() => onBulkAction(executeDelete, true)}
            >
              <IconTrash />
            </div>
          </>
        );
      } else if (folder === "inbox") {
        return (
          <>
            <div
              className="action"
              onClick={() => onBulkAction(executeCopyToMyOverlays)}
            >
              <IconCopy />
            </div>
            <div
              className="action"
              onClick={() => onBulkAction(executeDelete, false)}
            >
              <IconTrash />
            </div>
          </>
        );
      } else {
        return (
          <>
            <FolderMenu
              folders={folders}
              rootFolderId={rootFolderId}
              dashboardType={dashboardType}
              onSelectFolder={(folder) =>
                onBulkAction(executeSetFolder, folder)
              }
            />
            <div
              className="action"
              onClick={() => onBulkAction(executeDelete, false)}
            >
              <IconTrash />
            </div>
          </>
        );
      }
    }
  };

  return (
    <div className="dashboard-header">
      <div className="action-area">
        <div
          className="selection"
          onClick={() => {
            let newSelection = [];
            if (numberOfSelectedItems === 0) {
              newSelection = items.map((item) => item.id);
            }
            ContextDispatch({
              type: "SET_DASHBOARD_SELECTION",
              payload: newSelection,
            });
          }}
        >
          <div className="checkbox">{renderCheckbox()}</div>
          <div className="message">{numberOfSelectedItems} selected</div>
        </div>

        {renderActions()}
      </div>

      <div className="tool-area">
        <div className="radio">
          <div
            className="icon"
            onClick={() => {
              ContextDispatch({
                type: "SET_DASHBOARD_VIEW",
                payload: "grid",
              });
            }}
          >
            <IconGrid
              fill={
                ContextState.dashboardView === "grid"
                  ? "var(--sl-yellow)"
                  : "var(--sl-white)"
              }
            />
          </div>
          <div
            className="icon"
            onClick={() => {
              ContextDispatch({
                type: "SET_DASHBOARD_VIEW",
                payload: "list",
              });
            }}
          >
            <IconList
              fill={
                ContextState.dashboardView === "list"
                  ? "var(--sl-yellow)"
                  : "var(--sl-white)"
              }
            />
          </div>
        </div>
        <div className="header-menu">
          <Menu
            menuButton={
              <MenuButton className="button">
                <>
                  <IconSort />
                  {
                    sortOptions.find(
                      (item) => item.id === ContextState.dashboardSort
                    ).name
                  }
                </>
              </MenuButton>
            }
            transition
            align="end"
            menuClassName="menu-background"
            offsetY={2}
          >
            {sortOptions.map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  onClick={() => {
                    ContextDispatch({
                      type: "SET_DASHBOARD_SORT",
                      payload: item.id,
                    });
                  }}
                  className="menu-item"
                >
                  <div>{item.name}</div>
                </MenuItem>
              );
            })}
          </Menu>
        </div>
        <div className="results">
          {busy
            ? ""
            : items.length + (items.length === 1 ? " result" : " results")}
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;

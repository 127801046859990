let saveTimeoutHandler = undefined;
let oldData = undefined;

const AppLocalStorage = {
  load: function (key, ContextDispatch) {
    const dispatch = (type, payload) => {
      if (payload !== undefined) {
        ContextDispatch({
          type: type,
          payload: payload,
        });
      }
    };

    // read from local storage and parse the string into a json object
    let json;
    try {
      json = JSON.parse(localStorage.getItem(key));
    } catch (e) {}
    if (!json) {
      dispatch("SET_STORAGE_LOADED", true);
      return;
    }

    // I renamed these properties in the store, so I need to rename them here
    if (json.myOverlaysSort) {
      json.dashboardSort = json.myOverlaysSort;
    }
    if (json.myOverlaysView) {
      json.dashboardView = json.myOverlaysView;
    }

    dispatch("SET_UNO_ACCESS_TOKEN", json.unoAccessToken);
    dispatch("SET_UNO_REFRESH_TOKEN", json.unoRefreshToken);
    dispatch("SET_THEME", json.theme);
    dispatch("SET_MENU_SIZE", json.menuSize);
    dispatch("SET_DASHBOARD_SORT", json.dashboardSort);
    dispatch("SET_DASHBOARD_VIEW", json.dashboardView);
    dispatch("SET_MENU_OVERLAY_SHOW", json.menuOverlayShow);
    dispatch("SET_MENU_OVERLAY_FOLDERS_SHOW", json.menuOverlayFoldersShow);
    dispatch("SET_MENU_IMAGE_SHOW", json.menuImageShow);
    dispatch("SET_MENU_IMAGE_FOLDERS_SHOW", json.menuImageFoldersShow);

    dispatch("SET_STORAGE_LOADED", true);
  },

  save: function (key, ContextState) {
    let data = JSON.stringify({
      unoAccessToken: ContextState.unoAccessToken,
      unoRefreshToken: ContextState.unoRefreshToken,
      theme: ContextState.theme,
      menuSize: ContextState.menuSize,
      dashboardSort: ContextState.dashboardSort,
      dashboardView: ContextState.dashboardView,
      menuOverlayShow: ContextState.menuOverlayShow,
      menuOverlayFoldersShow: ContextState.menuOverlayFoldersShow,
      menuImageShow: ContextState.menuImageShow,
      menuImageFoldersShow: ContextState.menuImageFoldersShow,
    });

    if (oldData !== data) {
      oldData = data;

      // delay data write so we don't write too often
      if (saveTimeoutHandler) {
        clearTimeout(saveTimeoutHandler);
        saveTimeoutHandler = undefined;
      }

      saveTimeoutHandler = setTimeout(() => {
        saveTimeoutHandler = undefined;
        try {
          localStorage.setItem(key, data);
        } catch (e) {}
      }, 300);
    }
  },

  saveValue: function (key, value) {
    try {
      localStorage.setItem(key, value);
    } catch (e) {}
  },

  loadValue: function (key, defaultValue) {
    let value;
    try {
      value = localStorage.getItem(key);
    } catch (e) {}
    if (!value) {
      value = defaultValue;
    }
    return value;
  },
};

export default AppLocalStorage;

import { useState, useEffect } from "react";

import Util from "../utilities/Util";
import { IconInfo } from "../utilities/SvgIcon";

const EmailInput = ({ value, onChange, onOk }) => {
  let defaultEmail = value === undefined ? "" : String(value).trim();
  const [email, setEmail] = useState(defaultEmail);
  const [isOk, setIsOk] = useState(Util.validateEmail(defaultEmail));

  // we enter the email
  const handleEmailChanged = (value) => {
    let ok = Util.validateEmail(String(value).trim());

    setIsOk(ok);
    setEmail(value);

    if (onChange) {
      onChange(value);
    }
    if (onOk) {
      onOk(ok);
    }
  };

  useEffect(() => {
    handleEmailChanged(defaultEmail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="label-input">
      <div className="label">Email</div>
      <input
        type="text"
        name="email"
        className={"input " + (isOk ? "" : "invalid")}
        value={email}
        onChange={(e) => handleEmailChanged(e.target.value)}
        autoFocus
      />
      {!isOk && (
        <div className="error-message">
          <IconInfo fill="var(--sl-action-red)" />
          Please enter a valid email
        </div>
      )}
    </div>
  );
};

export default EmailInput;

import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Context } from "../stores/store";
import Util from "../utilities/Util";
import Config from "../stores/Config";
import EmailInput from "./EmailInput";

import { IconSpinner, IconClose } from "../utilities/SvgIcon";

const ForgotPassword = ({ onClose }) => {
  const [email, setEmail] = useState("");
  const [busy, setBusy] = useState(false);

  const [ContextState, ContextDispatch] = useContext(Context);

  const navigate = useNavigate();

  const onButtonClick = (event) => {
    setBusy(true);

    fetch(Config.singularUrl + "/apiv2/users/forgotpassword", {
      method: "POST",
      body: JSON.stringify({
        email: email,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setBusy(false);
        Util.handleFetchError(result, ContextDispatch, navigate);
        Util.addNotification("An email has been sent to your email address");
        onClose();
      })
      .catch((error) => {
        setBusy(false);
      });
  };

  useEffect(() => {
    if (ContextState.authenticationStatus !== "none") {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ContextState.authenticationStatus]);

  return (
    <div className="content">
      <div className="forgotpassword-header">
        <img className="logo" src={Config.unoLogoMedium} alt="" />
        <span className="close" onClick={() => onClose()}>
          <IconClose />
        </span>
      </div>
      <div className="title">Reset your password</div>

      <div className="form">
        <EmailInput value={email} onChange={(value) => setEmail(value)} />
        <div className="offset"></div>
        <button className="button-brand" onClick={onButtonClick}>
          {!busy && <span>Submit</span>}
          {busy && <IconSpinner />}
        </button>
      </div>
    </div>
  );
};

export default ForgotPassword;

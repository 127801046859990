import mixpanel from 'mixpanel-browser';


const isProduction = process.env.NODE_ENV === 'production'
let MIXPANEL_API_TOKEN = '0284ab4f0cf3a8edd7fccfcf34950600'

if (window.location.host.indexOf('localhost') !== -1)
  MIXPANEL_API_TOKEN = 'ac4d65810446720b3e8e901f4b780379'

mixpanel.init(MIXPANEL_API_TOKEN, { debug: !isProduction, persistence: 'cookie' });

const mx = {
  track: (e, o) => {
    !isProduction && console.log('track *****', e)
    mixpanel.track(e, o)
  },
  identify: (account_id) => {
    !isProduction && console.log('identify *****', account_id)
    mixpanel.identify(account_id);
  },
  reset: () => {
    !isProduction && console.log('reset *****')
    mixpanel.reset();
  },
  track_pageview: (o) => {
    !isProduction && console.log('track_pageview');
    mixpanel.track_pageview(o);
  }
};

export default mx; 
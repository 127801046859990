import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Context } from "../stores/store";
import Util from "../utilities/Util";
import Config from "../stores/Config";
import mixpanel from "../utilities/MixPanel";

import FeatureComparison from "./FeatureComparison";
import SubscriptionStatus from "./SubscriptionStatus";

import {
  IconSubscriptionPlanFree,
  IconSubscriptionPlanPlus,
  IconSubscriptionPlanPro,
  IconSubscriptionPlanElite,
  IconSubscriptionCheck,
  IconSubscriptionCheckFilled,
  IconAngleLeft,
  IconSpinner,
  IconStars,
} from "../utilities/SvgIcon";

const SubscriptionPlans = [
  {
    id: "free",
    name: "uno Free",
    monthlyPrice: "0.00",
    yearlyPrice: "0.00",
    monthlyPriceId: null,
    yearlyPriceId: null,
    yearlyPayment: "0.00",
    yearlyDiscount: "0.00",
  },
  {
    id: "unoplus",
    name: "uno Plus",
    monthlyPrice: "2.99",
    yearlyPrice: "1.99",
    // test prices
    // monthlyPriceId: "price_1PNrypKLaMwQH1AaxO24aHIf",
    // yearlyPriceId: "price_1PNrypKLaMwQH1AaVy7BWNZk",
    monthlyPriceId: "price_1PPwbCKLaMwQH1Aah3lNK0Yw",
    yearlyPriceId: "price_1PPwbCKLaMwQH1Aa8QTMJxM0",
    yearlyPayment: "23.88",
    yearlyDiscount: "33%",
  },
  {
    id: "unopro",
    name: "uno Pro",
    monthlyPrice: "6.99",
    yearlyPrice: "4.99",
    monthlyPriceId: null,
    yearlyPriceId: null,
    yearlyPayment: "59.88",
    yearlyDiscount: "28%",
  },
  {
    id: "unoelite",
    name: "uno Elite",
    monthlyPrice: "59.99",
    yearlyPrice: "39.99",
    // test prices
    // monthlyPriceId: "price_1PNrzUKLaMwQH1AaVhK7NJiU",
    // yearlyPriceId: "price_1PNrzUKLaMwQH1AankrlvjcN",
    monthlyPriceId: "price_1PPwbEKLaMwQH1AaECRkrvnI",
    yearlyPriceId: "price_1PPwbEKLaMwQH1AaBfaAfaUe",
    yearlyPayment: "479.88",
    yearlyDiscount: "33%",
  },
];

const Subscription = ({
  isPriceOnly,
  onSetStripeProductId,
  onSetStripeProductName,
  onShowDialog,
}) => {
  const [ContextState] = useContext(Context);
  const [planPeriod, setPlanPeriod] = useState("month");

  const [stripeSubscription, setStripeSubscription] = useState(null);
  const [stripeSubscriptionBusy, setStripeSubscriptionBusy] = useState(true);
  const [stripeCancelBusy, setStripeCancelBusy] = useState(false);
  const [reloadUnoSubscription, setReloadUnoSubscription] = useState(0);

  const [changeSubscriptionPlans, setChangeSubscriptionPlans] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // fetch the current subscription status
    setStripeSubscriptionBusy(true);

    fetch(Config.paymentAPI + "uno-subscription", {
      method: "GET",
      headers: Util.getFetchHeaders(ContextState),
    })
      .then((res) => res.json())
      .then((data) => {
        setStripeSubscriptionBusy(false);
        setStripeSubscription(data);
      })
      .catch((error) => {
        setStripeSubscriptionBusy(false);
        Util.addNotification("Failed to fetch subscription status", "error");
        console.error("Error:", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ContextState.userInfo, reloadUnoSubscription]);

  useEffect(() => {
    if (isPriceOnly) {
      return;
    }

    if (ContextState.authenticationStatus !== "ok" || !ContextState.userInfo) {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ContextState.authenticationStatus, ContextState.userInfo, navigate]);

  const getSubscriptionPlan = (plan) => {
    return SubscriptionPlans.find((item) => item.id === plan);
  };

  const cancelSubscription = () => {
    setStripeCancelBusy(true);

    fetch(Config.paymentAPI + "uno-cancel-subscription", {
      method: "POST",
      headers: Util.getFetchHeaders(ContextState),
    })
      .then((res) => res.json())
      .then((data) => {
        setStripeCancelBusy(false);
        if (data && data.success) {
          mixpanel.track("Downgrade subscription to free successful");
          Util.addNotification(
            "Subscription downgraded successfully",
            "success"
          );
          setReloadUnoSubscription(reloadUnoSubscription + 1);
        } else {
          mixpanel.track("Downgrade subscription to free Failed");
          Util.addNotification("Failed to downgrade subscription", "error");
        }
      })
      .catch((error) => {
        setStripeCancelBusy(false);
        Util.addNotification("Failed to downgrade subscription", "error");
      });
  };

  // fetch the change subscription portal URL
  const fetchAndOpenChangeUrl = (priceId) => {
    fetch(
      Config.paymentAPI + `uno-change-subscription-portal?priceId=${priceId}`,
      {
        method: "GET",
        headers: Util.getFetchHeaders(ContextState),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        if (data.message) {
          Util.addNotification("Failed to change subscription", "error");
        } else {
          Util.openUrl(data.url);
          Util.registerToVisibilityChangeAndReload();
        }
      })
      .catch((error) => {
        Util.addNotification("Failed to change subscription", "error");
      });
  };

  const onChangeSubscription = (plan) => {
    if (isPriceOnly) {
      // open the login dialog
      onShowDialog("login");
      return;
    }

    if (plan === "free") {
      mixpanel.track("Click Downgrade subscription to Free");
      cancelSubscription();
      return;
    }

    const current = getCurrentSubscriptionPlan();
    if (current === plan || current === "singular") {
      return;
    }

    const subscriptionPlan = getSubscriptionPlan(plan);
    if (!subscriptionPlan) {
      return;
    }

    // get the price id
    let priceId;
    if (planPeriod === "month") {
      priceId = subscriptionPlan.monthlyPriceId;
    } else {
      priceId = subscriptionPlan.yearlyPriceId;
    }

    mixpanel.track("Click Subscribe", {
      plan: subscriptionPlan.name,
      period: planPeriod,
    });

    // we have a subscription plan. We need the Stripe UI to change the subscription
    if (current && current !== "free") {
      fetchAndOpenChangeUrl(priceId);
    } else {
      // show the checkout dialog
      onSetStripeProductId(priceId);
      onSetStripeProductName(subscriptionPlan.name);
      onShowDialog("stripecheckout");
    }
  };

  const getName = (plan) => {
    const subscriptionPlan = getSubscriptionPlan(plan);
    if (!subscriptionPlan) {
      return "unknown";
    } else {
      return subscriptionPlan.name;
    }
  };

  const getPrice = (plan, period) => {
    const subscriptionPlan = getSubscriptionPlan(plan);
    if (!subscriptionPlan) {
      return "0.00";
    }

    if (period === "month") {
      return subscriptionPlan.monthlyPrice;
    } else {
      return subscriptionPlan.yearlyPrice;
    }
  };

  const getCurrentSubscriptionPlan = () => {
    return Util.getSubscriptionPlan(ContextState.userInfo);
  };

  const renderSubscribeButton = (plan) => {
    const cancelAt = stripeSubscription && stripeSubscription.cancelAt;

    const current = getCurrentSubscriptionPlan();
    if (current === plan) {
      return (
        <div className="plan-current-plan promo">
          Current plan
          {cancelAt && <div className="no-renew">auto-renew canceled</div>}
        </div>
      );
    }

    if (plan === "free") {
      if (current === "singular" || cancelAt) {
        return <div className="plan-current-plan"></div>;
      }

      const busy = stripeCancelBusy || stripeSubscriptionBusy;

      return (
        <div
          className={"plan-button" + (busy ? " busy" : "")}
          onClick={() => {
            if (!busy) {
              onChangeSubscription("free");
            }
          }}
        >
          {busy && <IconSpinner className="busy delete" />}
          {!busy && <span>Downgrade</span>}
        </div>
      );
    }

    if (plan === "unoplus") {
      if (current === "singular") {
        return <div className="plan-current-plan"></div>;
      }

      if (current === "free") {
        return (
          <div
            className="plan-button promo"
            onClick={() => onChangeSubscription("unoplus")}
          >
            Subscribe Now!
          </div>
        );
      }
      if (current === "unoelite") {
        return (
          <div
            className="plan-button"
            onClick={() => onChangeSubscription("unoelite")}
          >
            Downgrade
          </div>
        );
      }
    }

    if (plan === "unopro") {
      return <div className="plan-current-plan">Coming soon</div>;
    }

    if (plan === "unoelite") {
      if (current === "singular") {
        return <div className="plan-current-plan">Current Plan</div>;
      }

      if (current === "free") {
        return (
          <div
            className="plan-button"
            onClick={() => onChangeSubscription("unoelite")}
          >
            Subscribe Now!
          </div>
        );
      }
      if (current === "unoplus") {
        return (
          <div
            className="plan-button promo"
            onClick={() => onChangeSubscription("unoelite")}
          >
            Subscribe Now!
          </div>
        );
      }
    }
  };

  const renderPlanDiscount = (plan) => {
    if (planPeriod === "month") {
      return null;
    }

    const subscriptionPlan = getSubscriptionPlan(plan);

    return (
      <div className="discount">
        <div className="discount-price">
          <div className="number">$ {subscriptionPlan.monthlyPrice}</div>
        </div>
        <div className="discount-info">
          Pay $ {subscriptionPlan.yearlyPayment} per year
        </div>
        <div className="discount-save">
          Save {subscriptionPlan.yearlyDiscount}{" "}
        </div>
      </div>
    );
  };

  const renderPlanPrice = (plan) => {
    const current = getCurrentSubscriptionPlan();

    // we do not show any price when singular is the current plan
    if (current === "singular") {
      if (plan === "unoelite") {
        return (
          <div className="plan-message">
            Your subscription is managed by Singular.live. Please visit{" "}
            <a
              className="plans-singular-nav-item"
              href="https://app.singular.live"
              target="_blank"
              rel="noreferrer"
            >
              app.singular.live
            </a>{" "}
            to change it.
          </div>
        );
      } else {
        return null;
      }
    }

    if (plan === "free") {
      return (
        <div className="plan-price">
          {planPeriod === "year" && <div className="discount"></div>}

          <div className="payment-price">
            <div className="payment-price">
              $ {getPrice("free", planPeriod)}
            </div>
            <span className="payment-date">per month</span>
          </div>
        </div>
      );
    }

    if (plan === "unoplus") {
      return (
        <div className="plan-price">
          {renderPlanDiscount("unoplus")}
          <div className="payment-price">
            <div className="payment-price">
              $ {getPrice("unoplus", planPeriod)}
            </div>
            <span className="payment-date">per month</span>
          </div>
        </div>
      );
    }

    if (plan === "unopro") {
      return (
        <div className="plan-price">
          {renderPlanDiscount("unopro")}
          <div className="payment-price">
            <div className="payment-price">
              $ {getPrice("unopro", planPeriod)}
            </div>
            <span className="payment-date">per month</span>
          </div>
        </div>
      );
    }

    if (plan === "unoelite") {
      return (
        <div className="plan-price">
          {renderPlanDiscount("unoelite")}
          <div className="payment-price">
            <div className="payment-price">
              $ {getPrice("unoelite", planPeriod)}
            </div>
            <span className="payment-date">per month</span>
          </div>
        </div>
      );
    }
  };

  const renderPlanBullet = (text, checkFilled) => {
    return (
      <div className="plan-bullets-bullet">
        {checkFilled ? (
          checkFilled
        ) : (
          <IconSubscriptionCheck fill="var(--sl-yellow)" />
        )}
        {text}
      </div>
    );
  };

  const renderPlanFree = () => {
    return (
      <div className="plan">
        <div className="plan-title">
          <IconSubscriptionPlanFree />
          {getName("free")}
        </div>
        <div className="plan-description">
          Get started and explore uno with no cost and no commitment
        </div>
        <div className="plan-bullets">
          {renderPlanBullet("Full access to uno overlay library")}
        </div>
        <div className="plan-bullets">
          {renderPlanBullet("Save unlimited overlays to your account")}
        </div>
        <div className="plan-bullets">
          {renderPlanBullet("25mb image storage")}
        </div>
        <div className="plan-offset"></div>
        {renderSubscribeButton("free")}
        {renderPlanPrice("free")}
      </div>
    );
  };

  const renderPlanUnoPlus = () => {
    return (
      <div className={"plan current"}>
        <div className="plan-title">
          <IconSubscriptionPlanPlus />
          {getName("unoplus")}
        </div>
        <div className="plan-description">
          Help support the uno team and remove the ads
        </div>
        <div className="plan-bullets">
          {renderPlanBullet(
            "Everything in " + getName("free"),
            <IconSubscriptionCheckFilled
              fill="var(--sl-yellow)"
              className="filled"
            />
          )}
          {renderPlanBullet("Removal of ads")}
          {renderPlanBullet("100mb image storage")}
          {renderPlanBullet("Much appreciated support for the uno team")}
        </div>
        <div className="plan-offset"></div>
        {renderSubscribeButton("unoplus")}
        {renderPlanPrice("unoplus")}
      </div>
    );
  };

  const renderPlanUnoPro = () => {
    return null;
    return (
      <div className="plan">
        <div className="plan-title">
          <IconSubscriptionPlanPro />
          {getName("unopro")}
        </div>
        <div className="plan-description">
          Coming soon - an account for power users with extended features
        </div>
        <div className="plan-bullets">
          {renderPlanBullet(
            "Everything in " + getName("unoplus"),
            <IconSubscriptionCheckFilled
              fill="var(--sl-yellow)"
              className="filled"
            />
          )}
          {renderPlanBullet("Custom fonts")}
          {renderPlanBullet("Huge gratitude and props from the uno team")}
          {renderPlanBullet(
            "Extra features being announced soon (we know - we're excited too!)"
          )}
        </div>
        <div className="plan-offset"></div>
        {renderSubscribeButton("unopro")}
        {renderPlanPrice("unopro")}
      </div>
    );
  };

  const renderPlanUnoElite = () => {
    return null;
    const unlockInboxBullet = (
      <div>
        Unlock inbox to receive custom overlays{" "}
        <a
          className="nav-item"
          href="https://resources.overlays.uno/pricing"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more
        </a>
      </div>
    );

    return (
      <div className="plan full-width">
        <div className="vertical">
          <div className="plan left-side">
            <div className="plan-title">
              <IconSubscriptionPlanElite />
              {getName("unoelite")}
              <div className="plan-badge">Enterprise</div>
            </div>
            <div className="plan-description">
              The king (or queen) of uno accounts! If you're really serious
              about uno and want to use custom built overlays with the biggest
              feature set we've got to offer - uno elite is calling your name.
            </div>
            <div className="plan-bullets">
              {renderPlanBullet(
                "Everything in " + getName("unoplus"),
                <IconSubscriptionCheckFilled
                  fill="var(--sl-yellow)"
                  className="filled"
                />
              )}
              {renderPlanBullet(
                unlockInboxBullet,
                <IconStars fill="var(--sl-yellow)" className="filled" />
              )}
              {renderPlanBullet("Multiple users")}
              {renderPlanBullet(
                <div>
                  At this level you're basically dating us
                  <br />
                  (Huge thank you from our team to yours)
                </div>
              )}
            </div>
          </div>
          <div className="plan right-side">
            {renderSubscribeButton("unoelite")}
            {renderPlanPrice("unoelite")}
          </div>
        </div>
      </div>
    );
  };

  const renderPlanPeriodSelection = () => {
    return (
      <div className="plans-period">
        <div
          className={
            "plans-period-button monthly" +
            (planPeriod === "month" ? " selected" : "")
          }
          onClick={() => setPlanPeriod("month")}
        >
          Monthly
        </div>
        <div
          className={
            "plans-period-button yearly" +
            (planPeriod === "year" ? " selected" : "")
          }
          onClick={() => setPlanPeriod("year")}
        >
          Yearly
          <div className="plans-period-button save">Save more</div>
        </div>
      </div>
    );
  };

  // special case for singular subscription
  if (getCurrentSubscriptionPlan() === "singular") {
    return (
      <div className="subscription">
        <div className="plans">
          <div className="plans-singular-header">
            Your current Subscription Plan
          </div>
          <div className="plans-singular-logo">
            <a href="https://singular.live" target="_blank" rel="noreferrer">
              <img src="/images/SingularLogoWhite.png" alt="Singular" />
            </a>
          </div>
          <div className="plans-singular-tagline">
            Your subscription is managed by Singular.live. Please visit{" "}
            <a
              className="plans-singular-nav-item"
              href="https://app.singular.live"
              target="_blank"
              rel="noreferrer"
            >
              app.singular.live
            </a>{" "}
            to change it.
          </div>
        </div>
      </div>
    );
  }

  // we are already subscribed to uno plus or uno elite
  if (changeSubscriptionPlans === false) {
    const plan = getCurrentSubscriptionPlan();
    if (plan === "unoplus" || plan === "unopro" || plan === "unoelite") {
      return (
        <SubscriptionStatus
          subscriptionPlans={SubscriptionPlans}
          stripeSubscription={stripeSubscription}
          stripeSubscriptionBusy={stripeSubscriptionBusy}
          onReloadUnoSubscription={() => {
            setReloadUnoSubscription(reloadUnoSubscription + 1);
          }}
          onChangePlans={() => {
            setChangeSubscriptionPlans(true);
          }}
        />
      );
    }
  }

  const renderSubscriptionHeader = () => {
    if (changeSubscriptionPlans) {
      return (
        <>
          <div
            className="plans-change-header"
            onClick={() => setChangeSubscriptionPlans(false)}
          >
            <IconAngleLeft className="icon" />
            Change Subscription Plan
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="plans-header">Choose your Subscription Plan</div>
          <div className="plans-tagline">
            Pay monthly or yearly and cancel whenever you feel like it
          </div>
        </>
      );
    }
  };

  // show the subscription plans
  return (
    <div className="subscription">
      <div className="plans">
        {renderSubscriptionHeader()}
        {renderPlanPeriodSelection()}
        <div className="plans-container">
          {renderPlanFree()}
          {renderPlanUnoPlus()}
          {renderPlanUnoPro()}
        </div>
        <div className="plans-container">{renderPlanUnoElite()}</div>
      </div>
      <FeatureComparison
        getCurrentPlan={(plan) => {
          return getCurrentSubscriptionPlan(plan);
        }}
        getPlanName={(plan) => {
          return getName(plan);
        }}
        getPlanPrice={(plan) => {
          return getPrice(plan, planPeriod);
        }}
        onChangePlan={(plan) => {
          onChangeSubscription(plan);
        }}
      />
    </div>
  );
};

export default Subscription;

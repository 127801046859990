import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

const HomeWelcome = ({ portalConfig, onWriteActionLog }) => {
  const [width, setWidth] = useState(0);
  const observedDiv = useRef(null);

  const navigate = useNavigate();

  const [imageLoop, setImageLoop] = useState(0);

  // track the width of the div
  const handleElementResized = () => {
    if (observedDiv.current && observedDiv.current.offsetWidth !== width) {
      setWidth(observedDiv.current.offsetWidth);
    }
  };

  const resizeObserver = new ResizeObserver(handleElementResized);

  //  set up the observer to track the width of the div
  useEffect(() => {
    if (observedDiv.current) {
      resizeObserver.observe(observedDiv.current);
    }
    return () => resizeObserver.disconnect();
  });

  // set up the timer to loop through the images
  useEffect(() => {
    const intervalHandler = setInterval(() => {
      // advance to the next image
      setImageLoop((v) => (v + 1) % portalConfig.promoLoop.length);
    }, 1000 * 7);
    return () => clearInterval(intervalHandler);
  }, [portalConfig.promoLoop.length]);

  if (!portalConfig) {
    return null;
  }

  // this is the promoloop we have. Either we have none, no text or with next
  let propmoLoopStatus;

  if (
    !portalConfig ||
    !portalConfig.promoLoop ||
    !portalConfig.promoLoop.length
  ) {
    propmoLoopStatus = "none";
  } else if (portalConfig.promoLoop.every((item) => !item.title)) {
    propmoLoopStatus = "noText";
  } else {
    propmoLoopStatus = "withText";
  }

  return (
    <div className="home-welcome">
      <a
        href={portalConfig.welcome.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="left">
          <img
            src={portalConfig.welcome.imageUrl}
            className="background"
            alt="welcome link"
          />
        </div>
      </a>

      {propmoLoopStatus === "noText" && (
        <div className="right-image-only">
          {portalConfig.promoLoop.map((item, index) => {
            const onClick = () => {
              if (item.link) {
                onWriteActionLog({
                  action: "promo",
                  type: "HomePageLoop",
                  value: item.title,
                });

                // check if the link starts with https
                if (item.link.startsWith("https://")) {
                  // open a new window
                  window.open(item.link, "_blank");
                } else {
                  // navigate to the link
                  navigate(item.link);
                }
              }
            };

            return (
              <div
                key={index}
                className={
                  "content-loop" + (imageLoop === index ? " active" : "")
                }
              >
                <div
                  className={"promo-image" + (item.link ? " link" : "")}
                  onClick={onClick}
                >
                  <img src={item.imageUrl} alt={item.imageAlt} />
                </div>
              </div>
            );
          })}
        </div>
      )}

      {propmoLoopStatus === "withText" && (
        <div
          className={"right " + (width < 700 ? "no-images" : "")}
          ref={observedDiv}
          style={{
            backgroundImage: "url(" + portalConfig.promoBackground + ")",
          }}
        >
          {portalConfig.promoLoop.map((item, index) => {
            const onClick = () => {
              if (item.link) {
                onWriteActionLog({
                  action: "promo",
                  type: "HomePageLoop",
                  value: item.title,
                });

                // check if the link starts with https
                if (item.link.startsWith("https://")) {
                  // open a new window
                  window.open(item.link, "_blank");
                } else {
                  // navigate to the link
                  navigate(item.link);
                }
              }
            };

            return (
              <div
                key={index}
                className={
                  "content-loop" + (imageLoop === index ? " active" : "")
                }
              >
                <div className="text-column">
                  <div className="title">{item.title}</div>
                  <div className="message">{item.message}</div>
                  {item.button && (
                    <div className="button" onClick={onClick}>
                      {item.button}
                    </div>
                  )}
                </div>
                <div
                  className={"promo-image" + (item.link ? " link" : "")}
                  onClick={onClick}
                >
                  <img src={item.imageUrl} alt={item.imageAlt} />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default HomeWelcome;

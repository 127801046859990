import React, { useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { Context } from "../stores/store";
import { IconSpinner } from "../utilities/SvgIcon";

import HomeWelcome from "./HomeWelcome";
import HomeFeatured from "./HomeFeatured";
// import HomeCategories from "./HomeCategories";
import HomeLibrarySet from "./HomeLibrarySet";

const Home = ({
  dialogState,
  onWriteActionLog,
  onShowDialog,
  onFilterChanged,
}) => {
  const location = useLocation();

  const [ContextState] = useContext(Context);

  useEffect(() => {
    if (location.pathname === "/home" && dialogState === "librarydetails") {
      onShowDialog("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, dialogState]);

  // search the library for overlays with "youtube.com/" in the info text
  const generateHallOfFame = () => {
    const result = [];
    if (!ContextState.subdomain) {
      ContextState.libraryContent.forEach((item) => {
        if (
          item.description &&
          item.description.toLowerCase().includes("youtube.com/")
        ) {
          // insert the item at a random position in the result array. This will
          // make the order of the items in the Hall of Fame random
          result.splice(Math.floor(Math.random() * result.length), 0, item);
          // result.push({ id: item.id });
        }
      });
    }

    return result;
  };

  // display a loading spinner
  if (!ContextState.portalConfig) {
    return (
      <div className="full-screen-panel">
        <IconSpinner />
      </div>
    );
  }

  const portalConfig = ContextState.portalConfig;

  return (
    <div className="home">
      <h1 className="title">{portalConfig.title}</h1>
      {portalConfig.tagline && <h2 className="tagline">{portalConfig.tagline}</h2>}
      {!portalConfig.tagline && <div className="tagline-spacer" />}
      <HomeWelcome
        portalConfig={portalConfig}
        onFilterChanged={onFilterChanged}
        onWriteActionLog={onWriteActionLog}
      />
      {/* <HomeCategories onFilterChanged={onFilterChanged} /> */}
      <HomeFeatured onFilterChanged={onFilterChanged} />
      {ContextState.libraryNew.length > 3 && (
        <HomeLibrarySet
          title="New and Updated Overlays"
          items={ContextState.libraryNew}
          libraryContent={ContextState.libraryContent}
          numberOfItems={50}
          onFilterChanged={onFilterChanged}
        />
      )}
      {/*
      <HomeLibrarySet
        title="Popular Overlays"
        items={ContextState.libraryPopular}
        libraryContent={ContextState.libraryContent}
        numberOfItems={10}
        onFilterChanged={onFilterChanged}
      />
      */}
      <HomeLibrarySet
        title="Trending Overlays"
        items={ContextState.libraryTrending}
        libraryContent={ContextState.libraryContent}
        numberOfItems={10}
        onFilterChanged={onFilterChanged}
      />

      <HomeLibrarySet
        title="Hall of fame"
        items={generateHallOfFame()}
        libraryContent={ContextState.libraryContent}
        onFilterChanged={onFilterChanged}
      />
    </div>
  );
};

export default Home;

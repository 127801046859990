import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { Context } from "../stores/store";

import {
  IconSpinner,
  IconLogin,
  IconSignup,
  IconSearch,
  IconFeedback,
  IconClose,
} from "../utilities/SvgIcon";

const Header = ({
  searchLibrary,
  searchMyOverlays,
  searchMyImages,
  searchInbox,
  onSearchLibrary,
  onSearchMyOverlays,
  onSearchMyImages,
  onSearchInbox,
  onShowDialog,
}) => {
  const location = useLocation();

  const [iconStatus, setIconStatus] = useState("busy");
  const [searchString, setSearchString] = useState("");

  const [ContextState] = useContext(Context);

  const getCurrentSection = () => {
    if (location.pathname.indexOf("/home") === 0) {
      return "home";
    }
    if (location.pathname.indexOf("/myoverlays") === 0) {
      return "myoverlays";
    }
    if (location.pathname.indexOf("/myimages") === 0) {
      return "myimages";
    }
    if (location.pathname.indexOf("/account") === 0) {
      return "account";
    }
    if (location.pathname.indexOf("/subscription") === 0) {
      return "subscription";
    }
    if (location.pathname.indexOf("/pricing") === 0) {
      return "pricing";
    }
    if (location.pathname.indexOf("/inbox") === 0) {
      return "inbox";
    }
    return "library";
  };

  const getSearchVisible = () => {
    const currentSection = getCurrentSection();
    if (
      currentSection === "account" ||
      currentSection === "subscription" ||
      currentSection === "pricing"
    ) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (ContextState.authenticationStatus === "ok") {
      setIconStatus("feedback");
    } else if (ContextState.authenticationStatus === "busy") {
      setIconStatus("busy");
    } else {
      setIconStatus("login");
    }
  }, [ContextState.authenticationStatus]);

  useEffect(() => {
    switch (getCurrentSection()) {
      case "myoverlays":
        setSearchString(searchMyOverlays);
        break;
      case "myimages":
        setSearchString(searchMyImages);
        break;
      case "inbox":
        setSearchString(searchInbox);
        break;
      default:
        setSearchString(searchLibrary);
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const onSearchExecute = (value) => {
    if (value === undefined) {
      value = searchString;
    }
    switch (getCurrentSection()) {
      case "myoverlays":
        onSearchMyOverlays(value);
        break;
      case "myimages":
        onSearchMyImages(value);
        break;
      case "inbox":
        onSearchInbox(value);
        break;
      default:
        onSearchLibrary(value);
        break;
    }
  };

  const renderRightIcons = () => {
    if (iconStatus === "feedback") {
      return (
        <div className="feedback" onClick={() => onShowDialog("sendfeedback")}>
          <IconFeedback />
          <span>Send Feedback</span>
        </div>
      );
    }
    if (iconStatus === "busy") {
      return <IconSpinner fill="#ffffff" />;
    }
    if (iconStatus === "login") {
      return (
        <>
          <div
            className="login"
            onClick={() => {
              onShowDialog("login");
            }}
          >
            <IconLogin fill="#ffffff" />
            Login
          </div>
          <div
            className="signup"
            onClick={() => {
              onShowDialog("signup");
            }}
          >
            <IconSignup fill="#ffffff" />
            Sign up
          </div>
        </>
      );
    }
  };

  const renderSearchIcon = () => {
    if (searchString) {
      return (
        <span
          className="icon-reset"
          onClick={() => {
            setSearchString("");
            onSearchExecute("");
          }}
        >
          <IconClose />
        </span>
      );
    } else {
      return <IconSearch className="icon" />;
    }
  };

  const getPlaceholder = () => {
    switch (getCurrentSection()) {
      case "myoverlays":
        return "Search My Overlays";
      case "myimages":
        return "Search My Images";
      case "inbox":
        return "Search Inbox";
      default:
        return "Search Library";
    }
  };

  return (
    <div
      className={
        "header " + (getCurrentSection() === "library" ? "shift-right" : "")
      }
    >
      <div className={"search-input " + (getSearchVisible() ? "" : "hidden")}>
        <input
          type="text"
          placeholder={getPlaceholder()}
          name="search"
          className="input"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Escape") {
              setSearchString("");
              onSearchExecute("");
            }
            if (e.key === "Enter" || e.key === "Tab") {
              onSearchExecute();
            }
          }}
        />
        {renderSearchIcon()}
      </div>

      <div className="offset"></div>
      <div className="right-area">{renderRightIcons()}</div>
    </div>
  );
};

export default Header;

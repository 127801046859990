import React, { useState } from "react";

import {
  IconCheckOn,
  IconCheckOff,
  IconAngleUp,
  IconAngleDown,
  IconPlus,
  IconMinus,
} from "../utilities/SvgIcon";

const START_NUMBER = 6;
const INCREASE_NUMBER = 5;

const LibraryFilterSection = ({ icon, title, tags, filter, onChanged }) => {
  const [show, setShow] = useState(true);
  const [numberShown, setNumberShown] = useState(START_NUMBER);

  if (!tags || !tags.length) {
    return <div></div>;
  }

  const renderTags = () => {
    if (!show) {
      return;
    }

    let counter = 0;
    return tags.map((tag, index) => {
      if (!tag) {
        return undefined;
      }

      if (counter >= numberShown) {
        return undefined;
      }
      counter++;

      let isActive = !!(filter && filter.indexOf(tag) !== -1);

      return (
        <div
          key={index}
          className={"tag" + (isActive ? " selected" : "")}
          onClick={() => {
            let selected = filter || [];
            let index = selected.indexOf(tag);
            let newSelected;
            if (index === -1) {
              newSelected = [...selected, tag];
            } else {
              newSelected = [...selected];
              newSelected.splice(index, 1);
            }
            if (onChanged) {
              onChanged(newSelected);
            }
          }}
        >
          <div>
            {isActive && <IconCheckOn />}
            {!isActive && <IconCheckOff />}
          </div>
          {tag}
        </div>
      );
    });
  };

  const renderShowMore = () => {
    if (!show) {
      return;
    }
    if (tags.length > numberShown) {
      return (
        <div
          className="show-more"
          onClick={() => setNumberShown(numberShown + INCREASE_NUMBER)}
        >
          Show more
          <IconPlus className="icon" />
        </div>
      );
    } else {
      if (tags.length > START_NUMBER) {
      return (
        <div className="show-more" onClick={() => setNumberShown(START_NUMBER)}>
          Show less
          <IconMinus className="icon" />
        </div>
      );
      }
    }
  };

  const renderReset = () => {
    if (filter && filter.length) {
      return (
        <div
          className="clear"
          onClick={(e) => {
            onChanged([]);
            e.stopPropagation();
          }}
        >
          Clear
        </div>
      );
    }
  };

  return (
    <div className="filter-section">
      {title && (
        <div className="title" onClick={() => setShow(!show)}>
          <div className="icon">{icon}</div>
          <div className="label">{title}</div>
          {renderReset()}
          {show && <IconAngleUp className="show" />}
          {!show && <IconAngleDown className="show" />}
        </div>
      )}
      {renderTags()}
      {renderShowMore()}
    </div>
  );
};

export default LibraryFilterSection;

import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { Context } from "../stores/store";
import Util from "../utilities/Util";
import Config from "../stores/Config";
import mixpanel from "../utilities/MixPanel";

import { IconSpinner } from "../utilities/SvgIcon";

const SubscriptionStatus = ({
  subscriptionPlans,
  stripeSubscription,
  stripeSubscriptionBusy,
  onReloadUnoSubscription,
  onChangePlans,
}) => {
  const [ContextState] = useContext(Context);

  const [stripePortalUrlBusy, setStripePortalUrlBusy] = useState(false);
  const [stripeResumeBusy, setStripeResumeBusy] = useState(false);
  const [stripeCancelBusy, setStripeCancelBusy] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (ContextState.authenticationStatus !== "ok" || !ContextState.userInfo) {
      navigate("/login");
    }
  }, [ContextState.authenticationStatus, ContextState.userInfo, navigate]);

  // fetch the payment portal url
  const fetchAndOpenPortalUrl = () => {
    setStripePortalUrlBusy(true);

    fetch(Config.paymentAPI + "uno-customer-portal", {
      method: "GET",
      headers: Util.getFetchHeaders(ContextState),
    })
      .then((res) => res.json())
      .then((data) => {
        setStripePortalUrlBusy(false);
        if (data.message) {
          Util.addNotification(
            "Failed to fetch manage subscription URL",
            "error"
          );
        } else {
          mixpanel.track("Click Manage Subscription");

          Util.openUrl(data.url);

          // we can remove this in the future
          Util.registerToVisibilityChangeAndReload();
        }
      })
      .catch((error) => {
        setStripePortalUrlBusy(false);
        Util.addNotification(
          "Failed to fetch manage subscription URL",
          "error"
        );
      });
  };

  const cancelSubscription = () => {
    setStripeCancelBusy(true);
    mixpanel.track("Click Cancel Subscription");

    fetch(Config.paymentAPI + "uno-cancel-subscription", {
      method: "POST",
      headers: Util.getFetchHeaders(ContextState),
    })
      .then((res) => res.json())
      .then((data) => {
        setStripeCancelBusy(false);
        if (data && data.success) {
          mixpanel.track("Cancel Subscription Successful");
          Util.addNotification("Subscription canceled successfully", "success");
          onReloadUnoSubscription();
        } else {
          mixpanel.track("Cancel Subscription Failed");
          Util.addNotification("Failed to cancel subscription", "error");
        }
      })
      .catch((error) => {
        setStripeCancelBusy(false);
        Util.addNotification("Failed to cancel subscription", "error");
      });
  };

  const resumeSubscription = () => {
    setStripeResumeBusy(true);

    mixpanel.track("Click Resume Subscription");

    fetch(Config.paymentAPI + "uno-resume-subscription", {
      method: "POST",
      headers: Util.getFetchHeaders(ContextState),
    })
      .then((res) => res.json())
      .then((data) => {
        setStripeResumeBusy(false);
        if (data && data.success) {
          mixpanel.track("Resume Subscription Successful");
          Util.addNotification(
            "Subscription reactivated successfully",
            "success"
          );
          onReloadUnoSubscription();
        } else {
          mixpanel.track("Resume Subscription Failed");
          Util.addNotification("Failed to reactivate subscription", "error");
        }
      })
      .catch((error) => {
        setStripeResumeBusy(false);
        Util.addNotification("Failed to reactivate subscription", "error");
      });
  };

  const getSubscriptionPlan = (plan) => {
    return subscriptionPlans.find((item) => item.id === plan);
  };

  const getCurrentSubscriptionPlan = () => {
    return Util.getSubscriptionPlan(ContextState.userInfo);
  };

  const getCurrentSubscriptionPlanName = () => {
    const plan = getCurrentSubscriptionPlan();

    const subscriptionPlan = getSubscriptionPlan(plan);
    if (!subscriptionPlan) {
      return "unknown";
    }

    return subscriptionPlan.name;
  };

  // special case for singular subscription
  if (getCurrentSubscriptionPlan() === "singular") {
    return (
      <div className="subscription">
        <div className="plans">
          <div className="plans-singular-header">
            Your current Subscription Plan
          </div>
          <div className="plans-singular-logo">
            <a href="https://singular.live" target="_blank" rel="noreferrer">
              <img src="/images/SingularLogoWhite.png" alt="Singular" />
            </a>
          </div>
          <div className="plans-singular-tagline">
            Your subscription is managed by Singular.live. Please visit{" "}
            <a
              className="plans-singular-nav-item"
              href="https://app.singular.live"
              target="_blank"
              rel="noreferrer"
            >
              app.singular.live
            </a>{" "}
            to change it.
          </div>
        </div>
      </div>
    );
  }

  // show if we renew the subscription or if we cancel it and when it ends
  const renderStatus = () => {
    if (stripeSubscriptionBusy) {
      return (
        <div className="status-line">
          <div className="line">
            <div className="title">
              <br />
            </div>
            <div className="text">
              <IconSpinner className="busy small" />
            </div>
          </div>
        </div>
      );
    } else {
      if (stripeSubscription && stripeSubscription.cancelAt) {
        return (
          <div className="status-line">
            <div className="line warning">
              <div className="title">End Date</div>
              <div className="text">
                {moment(stripeSubscription.cancelAt * 1000).format("LL")}
              </div>
            </div>
            <div className="info">
              After this date, you will lose access to the plan features, and
              your current subscription cannot be reactivated.
            </div>
          </div>
        );
      } else {
        let formattedDate = "invalid date";
        if (stripeSubscription && stripeSubscription.currentPeriodEnd) {
          formattedDate = moment(
            stripeSubscription.currentPeriodEnd * 1000
          ).format("LL");
        }
        return (
          <div className="status-line">
            <div className="line">
              <div className="title">Renewal Date</div>
              <div className="text">{formattedDate}</div>
            </div>
            <div className="info">
              This is the next date on which your subscription fee will be
              charged.
            </div>
          </div>
        );
      }
    }
  };

  const renderPaymentPortalButton = () => {
    if (!stripeSubscription) {
      return null;
    }

    return (
      <div
        className={"button" + (stripePortalUrlBusy ? " disabled" : "")}
        onClick={() => fetchAndOpenPortalUrl()}
      >
        {stripePortalUrlBusy && <IconSpinner className="busy" />}
        {!stripePortalUrlBusy && <span>Manage Payment Details</span>}
      </div>
    );
  };

  const renderCancelSubscriptionButton = () => {
    if (
      !stripeSubscription ||
      (stripeSubscription && stripeSubscription.cancelAt)
    ) {
      return null;
    }

    const busy = stripeCancelBusy || stripeSubscriptionBusy;

    return (
      <div
        className={"button delete" + (busy ? " disabled" : "")}
        onClick={() => {
          if (!busy) {
            cancelSubscription();
          }
        }}
      >
        {busy && <IconSpinner className="busy delete" />}
        {!busy && <span>Cancel Subscription</span>}
      </div>
    );
  };

  const renderReactivateSubscriptionButton = () => {
    if (!stripeSubscription || !stripeSubscription.cancelAt) {
      return null;
    }

    const busy = stripeResumeBusy || stripeSubscriptionBusy;

    return (
      <div
        className={"button promo" + (busy ? " disabled" : "")}
        onClick={() => {
          if (!busy) {
            resumeSubscription();
          }
        }}
      >
        {busy && <IconSpinner className="busy" />}
        {!busy && <span>Reactivate Subscription</span>}
      </div>
    );
  };

  const renderCurrentPlanStatus = () => {
    if (stripeSubscriptionBusy) {
      return <IconSpinner className="busy small" />;
    }

    if (stripeSubscription && stripeSubscription.cancelAt) {
      return <span>Cancelled</span>;
    }
    return <span>Active</span>;
  };

  const renderCurrentPlanPrice = () => {
    if (stripeSubscriptionBusy) {
      return <IconSpinner className="busy small" />;
    }

    let result = <span>not found</span>;

    if (stripeSubscription) {
      // loop through the subscriptionPlans and find the priceId
      // then check if the price is monthly or yearly
      const priceId = stripeSubscription.priceId;

      subscriptionPlans.forEach((item) => {
        if (item.monthlyPriceId === priceId) {
          result = <span>$ {item.monthlyPrice} / month</span>;
        }
        if (item.yearlyPriceId === priceId) {
          result = <span>$ {item.yearlyPayment} / year</span>;
        }
      });
    }
    return result;
  };

  const renderCurrentPlanStartDate = () => {
    if (stripeSubscriptionBusy) {
      return <IconSpinner className="busy small" />;
    }

    if (stripeSubscription && stripeSubscription.currentPeriodStart) {
      return (
        <span>
          {moment(stripeSubscription.currentPeriodStart * 1000).format("LL")}
        </span>
      );
    }
    return <span>not found</span>;
  };

  const renderCallToActionBanner = () => {
    return (
      <div
        className="call-to-action"
        style={{
          backgroundImage: "url('./images/HomePromoBackground.jpg')",
        }}
      >
        <div className="call-to-action-title">Upgrade now !</div>
        <div className="text">
          Unlock more features and improve your experience with Uno.
        </div>
        <div
          className="button"
          onClick={() => {
            mixpanel.track("Click Explore All Plans");
            onChangePlans();
          }}
        >
          <span>Explore all Plans</span>
        </div>
      </div>
    );
  };

  return (
    <div className="subscription-status">
      <div className="subscription-status-header">
        Your current Subscription Plan
      </div>
      <div className="line">
        <div className="title">Subscription Plan</div>
        <div className="text">{getCurrentSubscriptionPlanName()}</div>
      </div>
      <div className="line">
        <div className="title">Status</div>
        <div className="text">{renderCurrentPlanStatus()}</div>
      </div>
      <div className="line">
        <div className="title">Price</div>
        <div className="text">{renderCurrentPlanPrice()}</div>
      </div>
      <div className="line">
        <div className="title">Start Date</div>
        <div className="text">{renderCurrentPlanStartDate()}</div>
      </div>
      {renderStatus()}
      <div className="button-line">
        {renderPaymentPortalButton()}
        {renderCancelSubscriptionButton()}
        {renderReactivateSubscriptionButton()}
      </div>
      {renderCallToActionBanner()}
    </div>
  );
};

export default SubscriptionStatus;

import React, { useState, useContext, useRef } from "react";
import { Context } from "../stores/store";
import { useNavigate } from "react-router-dom";
import Config from "../stores/Config";
import Util from "../utilities/Util";

import Moment from "moment";
import CopyToClipboard from "react-copy-to-clipboard";

import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";

import {
  IconSpinner,
  IconEdit,
  IconEditText,
  IconCopy,
  IconMenu,
  IconTrash,
  IconRestore,
  IconFavorites,
  IconSearch,
  IconCheckOn,
  IconCheckOff,
  IconFavoriteOff,
  IconFavoriteOn,
  IconNewImage,
  IconUploadImage,
} from "../utilities/SvgIcon";

import Folder from "../features/Folder";

const DashboardImagessList = ({
  apps,
  filtered,
  view,
  folder,
  itemIdBusy,
  onFilterChanged,
  onAddItem,
  onUpdateItem,
  onLoadItems,
  onSetItemIdBusy,
  onShowDialog,
}) => {
  const [itemIdRename, setItemIdRename] = useState(0);
  const [itemNewName, setItemNewName] = useState("");
  const itemOldName = useRef();

  const navigate = useNavigate();

  const [ContextState, ContextDispatch] = useContext(Context);

  const onItemDelete = (id) => {
    onSetItemIdBusy(id, true);

    fetch(Config.singularUrl + "/apiv2/images/" + id, {
      method: "DELETE",
      headers: Util.getFetchHeaders(ContextState),
    })
      .then((res) => res.json())
      .then((result) => {
        onSetItemIdBusy(id, false);
        Util.handleFetchError(result, ContextDispatch, navigate);
        onLoadItems();
      })
      .catch((error) => {
        onSetItemIdBusy(id, false);
      });
  };

  const onItemRestore = (id) => {
    onSetItemIdBusy(id, true);

    fetch(Config.singularUrl + "/apiv2/images/" + id + "/restore", {
      method: "PUT",
      headers: Util.getFetchHeaders(ContextState),
    })
      .then((res) => res.json())
      .then((result) => {
        onSetItemIdBusy(id, false);
        Util.handleFetchError(result, ContextDispatch, navigate);
        onLoadItems();
      })
      .catch((error) => {
        onSetItemIdBusy(id, false);
      });
  };

  const onItemDeleteForever = (id) => {
    onSetItemIdBusy(id, true);

    fetch(Config.singularUrl + "/apiv2/images/" + id, {
      method: "DELETE",
      headers: Util.getFetchHeaders(ContextState),
      body: JSON.stringify({ permanent: true }),
    })
      .then((res) => res.json())
      .then((result) => {
        onSetItemIdBusy(id, false);
        Util.handleFetchError(result, ContextDispatch, navigate);
        onLoadItems();
      })
      .catch((error) => {
        onSetItemIdBusy(id, false);
      });
  };

  const onItemFavorite = (id, favorite) => {
    onSetItemIdBusy(id, true);

    fetch(Config.singularUrl + "/apiv2/images/" + id, {
      method: "PATCH",
      headers: Util.getFetchHeaders(ContextState),
      body: JSON.stringify({ favorite: favorite }),
    })
      .then((res) => res.json())
      .then((result) => {
        onSetItemIdBusy(id, false);

        Util.handleFetchError(result, ContextDispatch, navigate);
        onUpdateItem(result);
      })
      .catch((error) => {
        onSetItemIdBusy(id, false);
      });
  };

  const onItemSetFolder = (id, folder) => {
    onSetItemIdBusy(id, true);

    fetch(Config.singularUrl + "/apiv2/images/" + id, {
      method: "PATCH",
      headers: Util.getFetchHeaders(ContextState),
      body: JSON.stringify({ folder: folder }),
    })
      .then((res) => res.json())
      .then((result) => {
        onSetItemIdBusy(id, false);
        Util.handleFetchError(result, ContextDispatch, navigate);
        onLoadItems();
      })
      .catch((error) => {
        onSetItemIdBusy(id, false);
      });
  };

  const renderListItemActions = (item) => {
    if (folder === "trash") {
      return (
        <>
          <div
            className="menu"
            onClick={(e) => {
              onItemRestore(item.id);
              e.stopPropagation();
            }}
            onDoubleClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="button">
              <div className="back"></div>
              <IconRestore className="icon" />
            </div>
            <div className="label">Restore</div>
          </div>

          <div className="offset"></div>
          <div className="offset"></div>
          <div className="offset"></div>
          <div
            className="menu"
            onClick={(e) => {
              onItemDeleteForever(item.id);
              e.stopPropagation();
            }}
            onDoubleClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="button">
              <div className="back"></div>
              <IconTrash className="icon" />
            </div>
            <div className="label">Delete Permanently</div>
          </div>
        </>
      );
    }

    return (
      <>
        <div
          className="menu"
          onClick={(e) => {
            Util.openUrl(item.url);
            e.stopPropagation();
          }}
          onDoubleClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="button">
            <div className="back"></div>
            <IconEdit className="icon" />
          </div>
          <div className="label">Open</div>
        </div>

        <div className="offset"></div>

        <CopyToClipboard
          options={{ format: "text/plain" }}
          text={item.url}
          onCopy={() => {
            Util.addNotification("Link copied to clipboard");
          }}
        >
          <div
            className="menu"
            onDoubleClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="button">
              <div className="back"></div>
              <IconCopy className="icon" />
            </div>
            <div className="label">Copy URL</div>
          </div>
        </CopyToClipboard>
      </>
    );
  };

  const renderMenu = (item, mode) => {
    if (folder === "trash") {
      if (mode === "list") {
        return;
      }
      return (
        <div className="menu border" onClick={(e) => e.stopPropagation()}>
          <Menu
            menuButton={
              <MenuButton
                onClick={(e) => e.stopPropagation()}
                onDoubleClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <>
                  <div className="button">
                    <div className="back"></div>
                    <IconMenu className="icon" />
                  </div>
                </>
              </MenuButton>
            }
            transition
            align="end"
            menuClassName="menu-background"
          >
            <MenuItem
              onClick={() => onItemRestore(item.id)}
              className="menu-item"
            >
              <IconRestore />
              Restore
            </MenuItem>
            <MenuItem
              onClick={() => onItemDeleteForever(item.id)}
              className="menu-item last"
            >
              <IconTrash />
              Delete Permanently
            </MenuItem>
          </Menu>
        </div>
      );
    }

    return (
      <>
        <div className="offset"></div>
        <div className="vertical-line"></div>
        <div className="offset"></div>

        <div className="menu border" onClick={(e) => e.stopPropagation()}>
          <Menu
            menuButton={
              <MenuButton
                onClick={(e) => e.stopPropagation()}
                onDoubleClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <>
                  <div className="button">
                    <div className="back"></div>
                    <IconMenu className="icon" />
                  </div>
                  {mode === "list" && <div className="label">More</div>}
                </>
              </MenuButton>
            }
            transition
            align="end"
            menuClassName="menu-background"
          >
            <CopyToClipboard
              options={{ format: "text/plain" }}
              text={item.url}
              onCopy={() => {
                Util.addNotification("Link copied to clipboard");
              }}
            >
              <MenuItem className="menu-item">
                <IconCopy />
                <span>Copy URL</span>
              </MenuItem>
            </CopyToClipboard>

            <MenuItem
              onClick={() => {
                setItemNewName(item.name);
                setItemIdRename(item.id);
                itemOldName.current = item.name;
              }}
              className="menu-item"
            >
              <IconEditText />
              Rename
            </MenuItem>
            <MenuItem
              onClick={() => onItemDelete(item.id)}
              className="menu-item last"
            >
              <IconTrash />
              Move to Trash
            </MenuItem>
          </Menu>
        </div>
      </>
    );
  };

  const onHandleRenameInput = (e) => {
    const resetSetItem = () => {
      onSetItemIdBusy(itemIdRename, false);
      setItemIdRename(0);
      setItemNewName("");
    };

    if (e.key === "Escape") {
      resetSetItem();
      return;
    }

    if (e.key === "Enter" || e.key === "Tab") {
      let newName = itemNewName.trim();
      if (newName && newName === itemOldName.current) {
        resetSetItem();
      } else {
        onSetItemIdBusy(itemIdRename, true);
        fetch(Config.singularUrl + "/apiv2/images/" + itemIdRename, {
          method: "PATCH",
          headers: Util.getFetchHeaders(ContextState),
          body: JSON.stringify({ name: newName }),
        })
          .then((res) => res.json())
          .then((result) => {
            resetSetItem();
            Util.handleFetchError(result, ContextDispatch, navigate);
            onUpdateItem(result);
          })
          .catch((error) => {
            resetSetItem();
          });
      }
    }
  };

  const renderName = (item) => {
    if (folder === "trash") {
      return (
        <div className="name-group">
          <div className="name">{item.name}</div>
        </div>
      );
    }
    if (item.id === itemIdRename) {
      return (
        <div
          className="name-group"
          onClick={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
          onDoubleClick={(e) => e.stopPropagation()}
        >
          <input
            type="text"
            className="input"
            value={itemNewName}
            onChange={(e) => setItemNewName(e.target.value)}
            onKeyDown={onHandleRenameInput}
            onFocus={(e) => e.target.select()}
            onBlur={(e) => setItemIdRename(0)}
            autoFocus
          />
        </div>
      );
    } else {
      return (
        <div
          className="name-group"
          onClick={(e) => {
            setItemIdRename(item.id);
            setItemNewName(item.name);
            itemOldName.current = item.name;
            e.stopPropagation();
          }}
          onMouseDown={(e) => e.stopPropagation()}
        >
          <div className="name">{item.name}</div>
          <IconEditText className="icon" />
        </div>
      );
    }
  };

  const renderChanged = (changed) => {
    if (changed) {
      let text = "a few seconds ago";
      if (Moment().diff(Moment(changed)) > 0) {
        text = Moment(changed).fromNow();
      }
      return (
        <div className="changed">
          {/* <IconChanged /> */}
          <span className="text">Last updated: {text}</span>
        </div>
      );
    } else {
      return <div className="changed"></div>;
    }
  };

  const renderSelection = (item) => {
    // check if the item is selected
    const isSelected = ContextState.dashboardSelection.includes(item.id);
    return (
      <div
        className={"selection" + (isSelected ? " active" : "")}
        onClick={(e) => {
          e.stopPropagation();
          ContextDispatch({
            type: "TOGGLE_DASHBOARD_SELECTION",
            payload: item.id,
          });
        }}
        onDoubleClick={(e) => {
          e.stopPropagation();
        }}
      >
        {isSelected ? (
          <IconCheckOn fill="var(--sl-orange)" />
        ) : (
          <IconCheckOff fill="var(--sl-dark)" />
        )}
      </div>
    );
  };

  const renderFavorite = (item) => {
    // check if the item is selected
    const isSelected = item.favorite;
    return (
      <div
        className={"favorite" + (isSelected ? " active" : "")}
        onClick={(e) => {
          onItemFavorite(item.id, !isSelected);
          e.stopPropagation();
        }}
        onDoubleClick={(e) => {
          e.stopPropagation();
        }}
      >
        {isSelected ? (
          <IconFavoriteOn fill="white" />
        ) : (
          <IconFavoriteOff fill="var(--sl-dark)" />
        )}
      </div>
    );
  };

  const renderGridItem = (item, index) => {
    const isBusy = itemIdBusy.includes(item.id);
    return (
      <div
        key={index}
        className={"item" + (isBusy ? " busy" : "")}
        onDoubleClick={(e) => {
          if (folder !== "trash") {
            Util.openUrl(item.url);
          }
          e.stopPropagation();
        }}
      >
        <div className="item-content">
          <div className="item-content-aspect">
            <div className="center">
              <img
                className="image"
                src={item.thumbnail}
                alt="thumbnail"
                loading="lazy"
              />
            </div>
          </div>
        </div>
        <div className="metadata">
          {renderName(item)}
          <Folder
            folders={ContextState.dashboardFoldersImages}
            folderId={item.folderId}
            rootFolderId={ContextState.dashboardRootFolderImages}
            dashboardType={"images"}
            disabled={folder === "trash"}
            onNavigate={(folderId) => {
              navigate("/myimages/" + folderId);
            }}
            onSetFolder={(folder) => onItemSetFolder(item.id, folder)}
          />
          {renderChanged(item.changed)}
          {renderMenu(item, "grid")}
        </div>

        {renderSelection(item)}
        {folder !== "trash" && renderFavorite(item)}

        {isBusy && (
          <div
            className="item-busy"
            onClick={(e) => e.stopPropagation()}
            onDoubleClick={(e) => e.stopPropagation()}
          >
            <IconSpinner />
          </div>
        )}
      </div>
    );
  };

  const renderListItem = (item, index) => {
    const isBusy = itemIdBusy.includes(item.id);
    return (
      <div
        key={index}
        className={"item" + (isBusy ? " busy" : "")}
        onDoubleClick={(e) => {
          if (folder !== "trash") {
            Util.openUrl(item.url);
          }
          e.stopPropagation();
        }}
      >
        <img
          className="image"
          src={item.thumbnail}
          alt="thumbnail"
          loading="lazy"
        />

        <div className="offset"></div>
        <div className="metadata">
          {renderName(item)}
          <Folder
            folders={ContextState.dashboardFoldersImages}
            folderId={item.folderId}
            rootFolderId={ContextState.dashboardRootFolderImages}
            dashboardType={"images"}
            disabled={folder === "trash"}
            onNavigate={(folderId) => {
              navigate("/myimages/" + folderId);
            }}
            onSetFolder={(folder) => onItemSetFolder(item.id, folder)}
          />
          {renderChanged(item.changed)}
        </div>

        {renderListItemActions(item)}
        {renderMenu(item, "list")}
        <div className="offset"></div>

        {renderSelection(item)}
        {folder !== "trash" && renderFavorite(item)}

        {isBusy && (
          <div
            className="item-busy"
            onClick={(e) => e.stopPropagation()}
            onDoubleClick={(e) => e.stopPropagation()}
          >
            <IconSpinner />
          </div>
        )}
      </div>
    );
  };

  const renderEmptyMessage = (folder) => {
    if (folder === "favorites") {
      return (
        <div className="message">
          <IconFavorites className="icon" />
          <div className="title">No Favorites</div>
          <div className="info">All your favorites will be listed here.</div>
        </div>
      );
    } else if (folder === "trash") {
      return (
        <div className="message">
          <IconTrash className="icon" />
          <div className="title">No Trash</div>
          <div className="info">
            Items moved to the trash will be deleted forever after 30 days
          </div>
        </div>
      );
    } else {
      return (
        <div className="message">
          <IconNewImage className="icon" />
          <div className="title">No Images</div>
          <div className="info">Drop images here or click the button below</div>
          <div
            className="new-overlay"
            onClick={() => onShowDialog("uploadimage")}
          >
            <IconUploadImage fill="#ffffff" />
            <span>Upload image</span>
          </div>
        </div>
      );
    }
  };

  const renderItems = () => {
    if (!apps.length) {
      return renderEmptyMessage(folder);
    }

    if (!filtered.length) {
      return (
        <div className="message">
          <IconSearch className="icon" />
          <div className="title">No Results</div>
          <div className="info">
            Sorry, there were no results that match your search. <br />
            Please try another word.
          </div>
        </div>
      );
    }

    if (view === "list") {
      return <div className="list">{filtered.map(renderListItem)}</div>;
    } else {
      // if we have less than 6 elements in the list then it looks silly. Just add empty divs to make it right
      let filler = [];
      if (filtered.length < 6) {
        filler = Array.apply(null, Array(6 - filtered.length)).map((x, i) => {
          return i;
        });
      }
      return (
        <div className="grid">
          {filtered.map(renderGridItem)}
          {filler.map((item, index) => {
            return <div key={"f" + index}></div>;
          })}
        </div>
      );
    }
  };

  return <div className="dashboard-images-list">{renderItems()}</div>;
};

export default DashboardImagessList;

import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./styles/index.scss";
import Store from "./stores/store";

const root = createRoot(document.getElementById("root"));
root.render(
  <Store>
    <App />
  </Store>
);

// removed strict mode, because it causes problems with the modal dialog library
// <React.StrictMode>
//   <Store>
//     <App />
//   </Store>
// </React.StrictMode>;
